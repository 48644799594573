import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { API_URL } from "modules/configApp";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@material-ui/core/Button";

import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { red } from "@material-ui/core/colors";

//import { emphasize, withStyles } from "@material-ui/core/styles";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import Chip from "@material-ui/core/Chip";
//import HomeIcon from "@material-ui/icons/Home";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DialogContent from "@material-ui/core/DialogContent";

import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
//import history from "history";

import * as memberActions from "redux/member/action";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    data,
    items,
    isLoadinggame: isLoading,
    itemsgame,
  } = useSelector((store) => store.member);
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpens(true);
    setScroll(scrollType);
  };

  const clickopen = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const goPage = (page) => {
    history.push(page);
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    dispatch(action2);
  }, []);

  const logingevo = async () => {
    setloadspin(true);

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=evolution";
    a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
    //window.open(link);
  };

  const loginsexy = async () => {
    setloadspin(true);
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/sexygamelist`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    //a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
    //window.open(link);
  };

  const loginqtech = async () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=qtech";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };

  const loginwmcasino = async () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=wmcasino";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };

  const logindgcasino = async () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=dgcasino";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };

  const loginezuki = async () => {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=ezuki";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };

  const loginsbobet = async () => {
    setloadspin(true);
    /*
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginsbobet`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });
    */
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=sbobet";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
    //window.open(link);
  };

  const loginsbobetsexy = async () => {
    setloadspin(true);

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=sexy";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
    //window.open(link);
  };

  const loginpgsoft = async () => {
    setloadspin(true);

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=pgsoft";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
    //window.open(link);
  };

  const loginsaba = async () => {
    setloadspin(true);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=saba";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };

  const loginhabanero = async () => {
    setloadspin(true);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = "/logingame?type=habanero";
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
  };

  const logintogame = async (
    name,
    type,
    photo,
    status,
    sbobettype,
    sbobetcode
  ) => {
    if (status == 1) {
      setloadspin(true);
      if (type == 0) {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href =
          "/logingame?type=" +
          photo +
          "&grouptype=" +
          sbobettype +
          "&groupcode=" +
          sbobetcode;
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
        setloadspin(false);
      } else {
        if (name == "Joker") {
          history.push("/play/" + name);
        } else if (name == "Evoplay") {
          history.push("/play/" + name);
        } else {
          history.push("/play/" + name + "/gamelist");
        }
      }
    }
  };

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  React.useEffect(() => {
    const action = memberActions.GameAllList(0);
    dispatch(action);
  }, []);

  const { items: item } = useSelector((store) => store.member);
  const showmenu = (menu) => {
    const action = memberActions.GameAllList(menu);
    dispatch(action);
  };

  const checktypegame = (type, index) => {
    if (type == 1 && index == 0) {
      return (
        <div class="xribbon2 xribbon-top-right">
          <span>Hot</span>
        </div>
      );
    }
    if ((type == 3 && index == 0) || index == 1) {
      return (
        <div class="xribbon2 xribbon-top-right">
          <span>Hot</span>
        </div>
      );
    }
    if ((type == 2 && index == 0) || index == 1 || index == 2) {
      return (
        <div class="xribbon2 xribbon-top-right">
          <span>Hot</span>
        </div>
      );
    }
    /*
    if (index == 2) {
      return (
        <div class="ribbon1 ribbon-top-right">
          <span>คาสิโน</span>
        </div>
      );
    }

    if (index == 3) {
      return (
        <div class="ribbon2 ribbon-top-right">
          <span>Hot</span>
        </div>
      );
    }
    */
  };

  return (
    <div className="bgboxcasinox">
      {" "}
      <div style={{ height: 50 }}></div>
      <Container maxWidth="md">
        <div style={{ height: 40 }} id="sport"></div>
        <div className="bgboxcasinox">
          <div style={{ height: 20 }} id="casino"></div>
          <div className="headsport">
            <span>SLOT</span>
          </div>
          <div style={{ height: 20 }}></div>
          <ul className="press">
            {itemsgame &&
              itemsgame
                .filter((x) => x.type == 3)
                .map((x, index) => (
                  <li key={x.code}>
                    <div
                      className="mmlogo"
                      onClick={() =>
                        logintogame(
                          x.code,
                          x.link,
                          x.photo,
                          x.status,
                          x.sbobettype,
                          x.sbobetcode
                        )
                      }
                    >
                      {checktypegame(x.type, index)}

                      <img
                        src={`/images/them1/${x.photo}.png`}
                        className={x.status == 1 ? "divlogo" : "divlogoclose"}
                      ></img>
                    </div>
                    <div className="divtextcasino">
                      {x.status == 1 ? (
                        x.name
                      ) : (
                        <font color="gray">ปิดปรับปรุง</font>
                      )}
                    </div>
                  </li>
                ))}
          </ul>

          <div style={{ height: 10 }}></div>
        </div>

        {loadspin && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
    </div>
  );
}
