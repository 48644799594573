import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";

import Grid from "@material-ui/core/Grid";

import { red } from "@material-ui/core/colors";

import * as loginAcions from "redux/signin/action";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#0b1b18",
    //backgroundColor: "#fff",
    opacity: 0.9,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawerHeaderFix: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    backgroundColor: "#181818",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  avatar: {
    backgroundColor: red[500],
  },
  money: {
    color: "#e2325a",
  },
}));

export default function PersistentDrawerRight() {
  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [openleft, setOpenleft] = React.useState(false);

  const goPage = (path) => {
    history.push(path);
    setOpenleft(false);
    setOpen(false);
  };

  return (
    <div className="mainbg">
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <div>
                {" "}
                <img src="/images/glogo3.png" height="50" />
              </div>

              <div>
                <ul className="menupress">
                  <li onClick={() => goPage("/promotion")}>
                    <img src="/images/pro1.png" height="25"></img>
                    <div>โปรโมชั่น</div>
                  </li>
                  <li onClick={() => goPage("/signin")}>
                    <img src="/images/login.png" height="25"></img>
                    <div>เข้าสู่ระบบ</div>
                  </li>
                  <li onClick={() => goPage("/signup")}>
                    <img src="/images/add-user2.png" height="25"></img>
                    <div>สมัครสมาชิก</div>
                  </li>
                </ul>

                {/*
 <span>
                {localStorage.getItem("LG") === "en" ? (
                  <img
                    src="/images/en1.png"
                    height="30"
                    className="imgth"
                    onClick={handleClick}
                  ></img>
                ) : (
                  <img
                    src="/images/th1.png"
                    height="30"
                    className="imgth"
                    onClick={handleClick}
                  ></img>
                )}
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => changeLanguage("en")}>
                    <img
                      src="/images/en1.png"
                      height="25"
                      onClick={() => changeLanguage("en")}
                    ></img>
                    Eng
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage("th")}>
                    <img
                      src="/images/th1.png"
                      height="25"
                      onClick={() => changeLanguage("th")}
                    ></img>
                    ไทย
                  </MenuItem>
                </Menu>
              </span>
                */}

                {/*
<span>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  className={clsx(open && classes.hide)}
                >
                  <img src="/images/dot-06.png" height="33" />
                </IconButton>
              </span>
                */}
              </div>
            </Grid>
          </Toolbar>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
        </main>
      </div>
    </div>
  );
}
