import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import * as memberActions from "redux/member/action";

import * as yup from "yup";
//import { yupResolver } from '@hookform/resolvers'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 135,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function MediaCard(props) {
  //const path = useRouteMatch();
  const { search } = useLocation();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      mobile: yup.string().required(),
      password: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ mobile, password }) => {
    const memberaff = localStorage.getItem("memberaff");
    const action = memberActions.Save({
      mobile: mobile,
      password,
      refurl: window.location.href,
      memberaff: memberaff ? memberaff : null,
      ...props,
    });
    dispatch(action);
  };

  useEffect(() => {
    const newaff = search.includes("aff");
    if (newaff) {
      const checkcode = search.split("=")[1];
      localStorage.setItem("memberaff", checkcode);
    }
  }, []);
  const dispatch = useDispatch();
  const { isLoading, message, count, show } = useSelector(
    (store) => store.signin
  );

  const {
    isLoading: isLoadingregis,
    message: messageregis,
    datanotify,
  } = useSelector((store) => store.member);

  const [checkbtn, setcheckbtn] = React.useState(1);

  const GoPage = () => {
    //setcheckbtn(2);

    history.push("/signin");
  };

  const handleCloseregis = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);
  return (
    <>
      <div style={{ height: 100 }}></div>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className="bgconh1">
          <Typography
            gutterBottom
            variant="h5"
            className="headtitle"
            component="h3"
          >
            สมัครสมาชิก
          </Typography>
          {/*
 <Typography
              gutterBottom
              variant="h5"
              style={{ textAlign: "center", color: "#fff" }}
              component="h3"
            >
              {t("hregister.label")}
            </Typography>
              */}
          <div style={{ height: 20 }}></div>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(submit)}
          >
            <input
              ref={register}
              type="text"
              id="username"
              name="mobile"
              class="form__input"
              placeholder="ป้อนเบอร์มือถือ (ไม่ต้องใส่ -)"
              autocomplete="off"
              onKeyPress={function (event) {
                var inputValue = event.charCode;
                if (inputValue < 48 || inputValue > 57) {
                  event.preventDefault();
                }
              }}
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="password"
              id="password"
              name="password"
              class="form__input"
              placeholder="รหัสผ่าน"
              autocomplete="off"
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />

            {message === 3 && (
              <Alert variant="outlined" severity="error">
                ข้อมูลสมาชิกไม่ถูกต้อง กรุณาป้อนใหม่อีกครั้ง !!
              </Alert>
            )}
            <div style={{ height: 20 }}></div>
            <div className="regispro">
              <b>เงื่อนไขและข้อตกลง</b>
              <br></br>
              1. ต้องใช้บัญชีธนาคารที่สมัครเท่านั้น ในการทำรายการฝากเงิน
              <br></br>
              2. เบอร์โทรศัพท์, ชื่อ-นามสกุล, เลขบัญชีธนาคาร, ชื่อบัญชีธนาคาร,
              IP อินเตอร์เน็ต ที่ใช้ในการสมัคร ห้ามซ้ำกัน<br></br>
              3. ห้ามใช้ข้อมูลผู้อื่นมาสมัครหรือเดิมพัน<br></br>
              4. ห้ามมิให้ผู้อื่นเข้าสู่ระบบมาเดิมพันบัญชีของตนเอง<br></br>
            </div>

            <div style={{ height: 20 }}></div>
            <div className="btnsubmit" onClick={submit2}>
              {!isLoadingregis && t("register.label")}
              {isLoadingregis && (
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
            <button type="submit" ref={btnSubmit} className="btnnone"></button>
            <div style={{ height: 50 }}></div>
            <div className="title2">
              <span>หรือคุณมีบัญชีแล้ว</span>
            </div>
            <div style={{ height: 10 }}></div>
            <div className="btnsubmit2" onClick={GoPage}>
              {t("signin.label")}
            </div>

            <div style={{ height: 10 }}></div>
          </form>
          <div style={{ height: 90 }}></div>
        </div>
        {isLoading && (
          <div className={classes.rootLoading}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Container>

      <Snackbar
        open={datanotify.show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseregis}
      >
        <Alert onClose={handleCloseregis} severity="error">
          {t("hlogindub.label")}
        </Alert>
      </Snackbar>
    </>
  );
}
