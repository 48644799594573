import React, { useState, useEffect } from "react";
import { getRemainingTimeUntilMstimestamp } from "./ConutdownTimerUntils";
import { FieldTimeOutlined } from "@ant-design/icons";
const defaultRemainTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
  end: 1,
};
const CountdownTimer = ({ countdownTimestampMs }) => {
  const [remainingTime, setRemainingTime] = useState(defaultRemainTime);
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainTime(countdownTimestampMs);
    }, 1000);
    return () => clearTimeout(intervalId);
  }, []);
  function updateRemainTime(countdown) {
    setRemainingTime(getRemainingTimeUntilMstimestamp(countdown));
  }
  return (
    <div className="appcountdown">
      <span>
        <FieldTimeOutlined />
      </span>
      {remainingTime.days != "00" && <span>{remainingTime.days}</span>}
      {remainingTime.days != "00" && <span>วัน</span>}
      {remainingTime.days == "00" &&
        remainingTime.hours == "00" &&
        remainingTime.minutes == "00" &&
        remainingTime.seconds == "00" &&
        remainingTime.end == 2 && <span>ยังไม่เปิดรับแทง</span>}

      {remainingTime.end != 2 && (
        <span>
          {remainingTime.hours}:{remainingTime.minutes}:{remainingTime.seconds}
        </span>
      )}
    </div>
  );
};

export default CountdownTimer;
