import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";

//import { emphasize, withStyles } from "@material-ui/core/styles";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import Chip from "@material-ui/core/Chip";
//import HomeIcon from "@material-ui/icons/Home";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
//import history from "history";

import * as memberActions from "redux/member/action";
import CountdownTimer from "./CountdownTimer";
import LottoBetlist from "./Lotto_Betlist";
import LottoMain from "./Lotto_Main";

import "./betlotto.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const {
    isLoadinggame: isLoading,
    lottototal,
    addlottototal,
    betamount,
    betlist3t,
    lottonotify,
    betlist3tod,
    betlist3f,
    betlist3l,
    betlist2t,
    betlist2l,
    betlistrt,
    betlistrl,
    tabcontent,
  } = useSelector((store) => store.member);
  const { tabmain } = tabcontent;
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
    setScroll("paper");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    dispatch(action2);
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  React.useEffect(() => {
    const action = memberActions.GameAllList(4);
    dispatch(action);
  }, []);

  const { items: item } = useSelector((store) => store.member);

  const { type } = useParams();

  const handlebtnclickrate = () => {
    const dispacrj = memberActions.btnclickrate();
    dispatch(dispacrj);
  };

  const removeall = () => {
    handleClickOpen2(true);
  };
  const confirmremove = () => {
    const memberdispacth = memberActions.handleremoveall();
    dispatch(memberdispacth);
    handleClose2(false);
  };

  const handleClose = () => {
    const memdispatch = memberActions.hidelottonotify();
    dispatch(memdispatch);
  };

  const handlebetall = (e) => {
    let items = {
      betamount: e.target.value,
      delitem: 2, // ประเภทแก้ไขราคาทั้งหมด
    };
    const memdispatch = memberActions.handleamount(items);
    dispatch(memdispatch);
  };

  const gopage = (pat) => {
    history.push(pat);
  };
  return (
    <>
      {" "}
      {tabmain == true && (
        <>
          {" "}
          <div className="headpaddingtop"></div>
          <Container
            maxWidth="md"
            style={{
              paddingTop: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
            }}
          >
            <Button
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              variant="outlined"
              onClick={() => gopage("/play/lotto")}
              className="btnback"
            >
              ย้อนกลับ
            </Button>
          </Container>
        </>
      )}
      {tabmain == false && (
        <>
          {" "}
          <div className="headpaddingtop"></div>
          <Container
            maxWidth="md"
            style={{
              paddingTop: 5,
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
            }}
          >
            <Button
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              variant="outlined"
              onClick={() => handlebtnclickrate()}
              className="btnback"
            >
              ย้อนกลับ
            </Button>
          </Container>
        </>
      )}
      <Container maxWidth="md">
        <div className="bgboxcasinox">
          <div style={{ height: 2 }} id="casino"></div>
          <div className="titlelottobet">
            {<CountdownTimer countdownTimestampMs={parseInt(type)} />}
          </div>
          <div style={{ height: 10 }}></div>

          <div className="topcontain">
            <div className="boxth"></div>
            <div className="headlotto">
              {tabmain == true ? (
                <span>หวยรัฐบาล งวดวันที่ 16/11/64</span>
              ) : (
                <span>ใส่ราคา {lottototal} รายการ</span>
              )}
            </div>

            <div className="lottocontaner">
              <div style={{ height: 3 }}></div>

              {tabmain == false && (
                <>
                  {" "}
                  <LottoBetlist betlist={betlistrt} numbertype="betlistrt" />
                  <LottoBetlist betlist={betlistrl} numbertype="betlistrl" />
                  <LottoBetlist betlist={betlist2t} numbertype="betlist2t" />
                  <LottoBetlist betlist={betlist2l} numbertype="betlist2l" />
                  <LottoBetlist betlist={betlist3t} numbertype="betlist3t" />
                  <LottoBetlist
                    betlist={betlist3tod}
                    numbertype="betlist3tod"
                  />
                  <LottoBetlist betlist={betlist3l} numbertype="betlist3l" />
                  <LottoBetlist betlist={betlist3f} numbertype="betlist3f" />
                  <ul className="lotto_press_submit">
                    <li>
                      {" "}
                      <div className="lotto_btn_close2">
                        ยอดแทง "เท่ากัน" หมด
                      </div>
                      <div className="lotto_btn_close_detail">
                        <input
                          type="number"
                          id="credit"
                          name="credit"
                          className="form__input"
                          placeholder="ใส่ยอดเงิน"
                          onChange={(e) => handlebetall(e)}
                        />
                      </div>
                    </li>
                    <li>
                      <div className="lotto_btn_close3">รวมยอดทั้งหมด</div>
                      <div className="lotto_btn_close_detail">
                        <input
                          type="text"
                          id="credit"
                          name="credit"
                          className="form__input2"
                          disabled
                          value={`${betamount} บาท`}
                        />
                      </div>
                    </li>
                  </ul>
                  <ul className="lotto_press_submit">
                    <li>
                      {" "}
                      <div className="lotto_list" onClick={() => removeall()}>
                        <BlockIcon className="mverti" /> ยกเลิกทั้งหมด
                      </div>
                    </li>
                    <li>
                      {" "}
                      <div
                        className="btndeposit"
                        onClick={() => handleClickOpen(true)}
                      >
                        <DoneIcon className="mverti" />
                        ส่งโพย
                      </div>
                    </li>
                  </ul>
                </>
              )}

              {tabmain == true && <LottoMain removeall={removeall} />}
            </div>
          </div>

          <div style={{ height: 10 }}></div>
        </div>

        {loadspin && (
          <div className="divLoading">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="divLoading">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
        <div className={classes.root}>
          <div style={{ height: 10 }}></div>
        </div>
      </Container>
      <div style={{ height: 80 }}></div>
      <Snackbar
        open={lottonotify}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          เพิ่มเลข {addlottototal} รายการ
        </Alert>
      </Snackbar>
      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ข้อความแจ้งเตือน</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            คุณแน่ใจนะว่าต้องการลบ เลขทั้งหมด ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2} variant="contained">
            ปิด
          </Button>
          <Button onClick={confirmremove} color="secondary" variant="contained">
            ลบ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
