import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import * as loginActions from "redux/signin/action";
import * as memberActions from "redux/member/action";

import * as yup from "yup";
//import { yupResolver } from '@hookform/resolvers'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //backgroundColor: "#fff",
    background: "transparent",
    border: "1px",
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    position: "absolute",
  },
  media: {
    height: 135,
    background: "transparent",
    //backgroundColor: "#bd0000",

    /* Center and scale the image nicely */
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}));

export default function MediaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      mobile: yup.string().required(),
      password: yup.string().required(),
    }),
    //validationSchema:
    /*
    validationSchema:yup.object.shape({
      UserName:yup.string().required(),
      password:yup.string().required()
    })
    */
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ mobile, password }) => {
    if (checkbtn == 1) {
      const action = loginActions.Login({ mobile, password, ...props });
      dispatch(action);
    } else {
      const action = memberActions.Save({ mobile, password, ...props });
      dispatch(action);
    }
  };

  useEffect(() => {}, []);
  const dispatch = useDispatch();
  const { isLoading, message, count, show } = useSelector(
    (store) => store.signin
  );

  const {
    isLoading: isLoadingregis,
    message: messageregis,
    datanotify,
  } = useSelector((store) => store.member);

  const [checkbtn, setcheckbtn] = React.useState(1);

  const GoPage = () => {
    //setcheckbtn(2);

    history.push("/signup");
  };

  const GoPageForgot = () => {
    history.push("/forgotpass");
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const action = loginActions.clearsignin();
    dispatch(action);
  };

  const handleCloseregis = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    const action = memberActions.GetList();
    dispatch(action);
  };

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);

  localStorage.removeItem("tokenstatus");
  localStorage.removeItem("Signature");
  localStorage.removeItem("AdminToken");
  localStorage.removeItem("mobile");
  localStorage.clear();
  return (
    <>
      <div style={{ height: 100 }}></div>
      <Container
        maxWidth="sm"
        style={{
          paddingTop: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className="bgconh1">
          <Typography
            gutterBottom
            variant="h5"
            className="headtitle"
            component="h3"
          >
            {t("signin.label")}
          </Typography>
          {/*
 <Typography
              gutterBottom
              variant="h5"
              style={{ textAlign: "center", color: "#fff" }}
              component="h3"
            >
              {t("hlogin.label")}
            </Typography>
              */}
          <div style={{ height: 20 }}></div>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(submit)}
          >
            <input
              ref={register}
              type="text"
              id="username"
              name="mobile"
              class="form__input"
              placeholder="ป้อนเบอร์มือถือ (ไม่ต้องใส่ -)"
              autocomplete="off"
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />
            <div style={{ height: 20 }}></div>
            <input
              ref={register}
              type="password"
              id="password"
              name="password"
              class="form__input"
              placeholder="รหัสผ่าน"
              autocomplete="off"
              //value={credit ? credit : null}
              //onChange={setCreditNew2}
            />

            {message === 3 && (
              <Alert variant="outlined" severity="error">
                ข้อมูลสมาชิกไม่ถูกต้อง กรุณาป้อนใหม่อีกครั้ง !!
              </Alert>
            )}
            <div style={{ height: 20 }}></div>

            <div className="btnsubmit" onClick={submit2}>
              {!isLoading && t("signin.label")}
              {isLoading && (
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
            <div className="forgotpass" onClick={GoPageForgot}>
              ลืมรหัสผ่าน ?
            </div>
            <button type="submit" ref={btnSubmit} className="btnnone"></button>
            <div style={{ height: 10 }}></div>
            <div className="title2">
              <span>หรือยังไม่มีบัญชี</span>
            </div>
            <div style={{ height: 10 }}></div>
            <div className="btnsubmit2" onClick={GoPage}>
              {t("register.label")}
            </div>

            <div style={{ height: 10 }}></div>
          </form>
          <div style={{ height: 90 }}></div>
        </div>
        {/*
<CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions>
     */}
      </Container>
      <Snackbar
        open={show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {t("hloginflase.label")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={datanotify.show}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseregis}
      >
        <Alert onClose={handleCloseregis} severity="error">
          {t("hlogindub.label")}
        </Alert>
      </Snackbar>
    </>
  );
}
