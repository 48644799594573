import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import CachedIcon from "@material-ui/icons/Cached";
import PhonelinkLockIcon from "@material-ui/icons/PhonelinkLock";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import RemoveCircleSharpIcon from "@material-ui/icons/RemoveCircleSharp";
import Alert from "@material-ui/lab/Alert";
import { red } from "@material-ui/core/colors";
import CreditCardIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import HistoryIcon from "@material-ui/icons/HistoryOutlined";
import GroupWorkIcon from "@material-ui/icons/GroupAddOutlined";
import RestorePageOutlinedIcon from "@material-ui/icons/RestorePageOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToAppOutlined";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderModeOutlined";
import MoneyIcon from "@material-ui/icons/MoneyOutlined";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
//import Container from "@material-ui/core/Container";
//import CircularProgress from "@material-ui/core/CircularProgress";

import { useTranslation } from "react-i18next";
import * as memberActions from "redux/member/action";
import * as uiActions from "redux/ui/action";
import * as loginAcions from "redux/ui/action";
import * as logoutAcions from "redux/signin/action";
import { addCommas } from "../../configApp";

//import Banner from "./Banner";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    background: "transparent",
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    paddingTop: 40,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
    background: "transparent",
    color: "#fff",
    textAlign: "center",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading, items, accountlist, accountlistotp } = useSelector(
    (store) => store.member
  );

  const history = useHistory();
  const goPage = (path) => {
    if (accountlistotp.length === 0) {
      history.push(path);
    } else {
      history.push("/dashboard/confirmotp");
    }
  };

  React.useEffect(() => {
    const action = memberActions.GetCredit({});
    dispatch(action);

    const actionacc = memberActions.GetAccount();
    dispatch(actionacc);

    const uiaction = uiActions.chatShow(false);
    dispatch(uiaction);
  }, [dispatch]);
  // console.log("items", items);

  const loadCredit = () => {
    const action = memberActions.GetCredit({});
    dispatch(action);
  };

  const logout = () => {
    dispatch(logoutAcions.logout(history));
  };
  console.log(accountlist);
  return (
    <>
      <Container maxWidth="md">
        <div class="containerx">
          {/*<Banner />*/}
          <div className="headhome"></div>

          <div className="topcontain">
            <div className="bgboxprofile">
              <div class="bgline">
                <PhonelinkLockIcon className="mverti" /> {items?.mobile}
                {accountlist.length > 0 && (
                  <span className="pright">
                    <img
                      src={`/images/bank/${accountlist[0].accbank}.png`}
                      height="25"
                      className="imgbank"
                    />
                    {accountlist[0].accbank} : {accountlist[0].accno}{" "}
                  </span>
                )}
              </div>
              <div class="bgline"></div>
            </div>
            <div className="mcredit">
              <div className="pcreditmoney">{t("balance.label")}</div>
              <div style={{ fontSize: 24 }} className="pcreditmoney2">
                {isLoading && <CircularProgress size={20} color="primary" />}
                {addCommas(
                  parseFloat(items?.credit ? items?.credit : 0).toFixed(0)
                )}
              </div>
              <div className="pcreditmoney">{t("bath.label")}</div>
            </div>
            <div className="topcontain_left">
              {" "}
              <div className="btndeposit" onClick={() => goPage("/deposit")}>
                <AddCircleIcon className="mverti" />
                ฝากเงิน
              </div>
            </div>

            <div className="topcontain_right">
              {" "}
              <div className="btnwithdraw" onClick={() => goPage("/withdraw")}>
                <RemoveCircleSharpIcon className="mverti" />
                ถอนเงิน
              </div>
            </div>
          </div>
          <div style={{ height: 10 }}></div>
          <ul className="listhome">
            <li onClick={() => goPage("/play/sport")}>
              <img src="/images/them1/hsport.gif"></img>
            </li>
            <li onClick={() => goPage("/play/casino")}>
              <img src="/images/them1/hcasino.gif"></img>
            </li>
            <li onClick={() => goPage("/play/slot")}>
              <img src="/images/them1/hslot.gif"></img>
            </li>
            <li>
              <img src="/images/them1/hlotto.gif"></img>
            </li>
          </ul>
          <div style={{ height: 10 }}></div>
          <div className="topcontain">
            <div style={{ height: 10 }}></div>
            <ul className="phome">
              <li onClick={() => goPage("/deposit")}>
                <div className="divm">
                  <CreditCardIcon style={{ fontSize: 44 }} />
                  <div>ฝาก</div>
                </div>
              </li>
              <li onClick={() => goPage("/withdraw")}>
                <div className="divm">
                  <MonetizationOnIcon style={{ fontSize: 44 }} />
                  <div>ถอน</div>
                </div>
              </li>
              <li onClick={() => goPage("/member/history")}>
                <div className="divm">
                  <HistoryIcon style={{ fontSize: 44 }} />
                  <div>ประวัติฝากถอน</div>
                </div>
              </li>
              <li onClick={() => goPage("/member/aff")}>
                <div className="divm">
                  <GroupWorkIcon style={{ fontSize: 44 }} />
                  <div>แนะนำเพื่อน</div>
                </div>
              </li>
              <li onClick={() => goPage("/member/bethistory")}>
                <div className="divm">
                  <RestorePageOutlinedIcon style={{ fontSize: 44 }} />
                  <div>ประวัติเดิมพัน</div>
                </div>
              </li>
              <li onClick={() => goPage("/member/changepassword")}>
                <div className="divm">
                  <LockIcon style={{ fontSize: 44 }} />
                  <div>เปลี่ยนรหัสผ่าน</div>
                </div>
              </li>
              <li>
                <div className="divm" style={{ marginTop: 7 }}>
                  <MoneyIcon style={{ fontSize: 44 }} />
                  <div>คืนยอดเสีย</div>
                </div>
              </li>
              <li>
                <div className="divm">
                  <img src="/images/them1/HL_PROMOTION.gif" height="50"></img>
                  <div>โปรโมชั่น</div>
                </div>
              </li>
              <li onClick={() => goPage("/chat")}>
                <div className="divm">
                  <img src="/images/them1/line1.gif" height="50"></img>
                  <div>แชท</div>
                </div>
              </li>
            </ul>
          </div>
          <div style={{ height: 20 }}></div>
          <ul className="phome2">
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ฝาก/ถอน เร็วระดับ วินาที
            </li>
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ที่นี่ ถอนได้ไม่ติดเทิร์น ทุกกรณี
            </li>
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              บริการ 24 ชม. ด้วย AI พร้อมบุคลากร กว่า 100 ชีวิต
            </li>
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              รวมเกมส์เดิมพันมากกว่า 50 ค่ายยักษ์ใหญ่
            </li>
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ระบบรันบน Cloud AWS lambda ปลอดภัยและเสถียรที่สุด
            </li>
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ข้อผิดพลาดใดๆ เรารับผิดชอบทุกกรณี ของจริงมีเพียง 1 เดียว
            </li>
          </ul>
        </div>
      </Container>
      <div style={{ height: 220 }}></div>
    </>
  );
}
