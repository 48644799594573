import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import PageList from "./PageList";
import Sport from "./Sport";
import Evoplay from "./Evoplay";
import Joker from "./Joker";
import Habanero from "./Habanero";
import GameList from "./Gamelist";
import Slot from "./Slot";
import Lotto from "./Lotto";
import LottoBet from "./LottoBet";

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/:webtype/gamelist`} component={GameList}></Route>
      <Route path={`${path}/:type/lottobet`} component={LottoBet}></Route>
      <Route path={`${path}/evoplay`} component={Evoplay}></Route>
      <Route path={`${path}/joker`} component={Joker}></Route>
      <Route path={`${path}/habanero`} component={Habanero}></Route>
      <Route path={`${path}/sport`} component={Sport}></Route>
      <Route path={`${path}/casino`} component={PageList}></Route>
      <Route path={`${path}/slot`} component={Slot}></Route>
      <Route path={`${path}/lotto`} component={Lotto}></Route>
      <Route path="/" component={PageList}></Route>
    </Switch>
  );
}
