import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import Badge from "@material-ui/core/Badge";
//import SmsIcon from "@material-ui/icons/Sms";
//import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";

//import PaymentIcon from "@material-ui/icons/Payment";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff",
    height: 93,
    //background: "transparent",
    //opacity: 0.9,
  },
  roottop: {
    position: "fixed",
    bottom: 100,
    width: "100%",
    background: "transparent",

    //opacity: 0.9,
  },
  txt: {
    color: "#fff",
    fontSize: 16,
  },
  txtbet: {
    color: "#f5cb08",
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  //const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const history = useHistory();
  const goPage = (path) => {
    history.push(path);
  };
  const { countchat } = useSelector((store) => store.chat);

  return (
    <div className="footer">
      <ul className="ufooter">
        <li onClick={() => goPage("/dashboard")}>
          <div className={pathname.includes("dashboard") && "acbuttom"}>
            <img src="/images/them1/homeAct.png"></img>
            <div className="acbottomac">หน้าแรก</div>
          </div>
        </li>
{
  /*
 <li onClick={() => goPage("/play/lotto")}>
          <div className={pathname.includes("lotto") && "acbuttom"}>
            <img src="/images/them1/huay.png"></img>
            <div className="acbottomac">แทงหวย</div>
          </div>
        </li>
  */
}
       

        <li onClick={() => goPage("/play/sport")}>
          <div className={pathname.includes("sport") && "acbuttom"}>
            <img src="/images/them1/ball.png"></img>
            <div className="acbottomac">แทงบอล</div>
          </div>
        </li>

        <li onClick={() => goPage("/play/casino")}>
          <div className={pathname.includes("casino") && "acbuttom"}>
            <img src="/images/them1/casino.png"></img>
            <div className="acbottomac">คาสิโน</div>
          </div>
        </li>
        <li onClick={() => goPage("/play/slot")}>
          <div className={pathname.includes("slot") && "acbuttom"}>
            <img src="/images/them1/sl.png"></img>
            <div className="acbottomac">สล๊อต</div>
          </div>
        </li>
        <li onClick={() => goPage("/chat")}>
          <div className={pathname.includes("chat") && "acbuttom"}>
            <img src="/images/linelogo.png" height="45"></img>
            <div className="acbottomac">แชท</div>
          </div>
        </li>
      </ul>
    </div>
  );
}
