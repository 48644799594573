//const API_URL = "https://www.apiadmin999.com";
const ENDPOINT = "https://apisocketadmin999.com";
//const API_URL = "http://localhost:80";
//const WEB_TOKEN = "FBjaVmbDcHML0WQuyhTnXryp5GyXLR";

const API_URL = "https://www.apiadmin777.com";
const WEB_TOKEN = "MzDB9jG7x48OFX6vejuvoXpsZ8ctWW"; // PTBIOGAME
//const WEB_TOKEN = "uTF8goJgWrqtdh8HwCxIEPqy0G1vOh"; // BIGBONUS777
//const WEB_TOKEN = "OdeT2pA9XVLi88RcHEs2kTaHbWtORQ"; // Okay333
const addCommas = (nStr) => {
  nStr += "";
  let x = nStr.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
};

const pageSize = {
  showSizeChanger: true,
  defaultPageSize: 20,
  pageSizeOptions: ["20", "50", "100"],
  showTotal: (total) => `ทั้งหมด ${addCommas(total)} รายการ`,
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const APP_TEXT = {
  TaxStatus: [
    { Id: 1, Name: "ยกเว้นภาษี" },
    { Id: 2, Name: "ไม่ยกเว้น" },
  ],
  key_txtstatus: { 1: "ยกเว้นภาษี", 2: "ไม่ยกเว้น" },
  status_approve: [
    { Id: 0, Name: "ไม่ต้องอนุมัติ" },
    { Id: 1, Name: "รออนุมัติ" },
    { Id: 2, Name: "Reject" },
    { Id: 3, Name: "อนุมัติแล้ว" },
  ],
  status_taxtype: [
    { Id: 1, Name: "ไม่มี" },
    { Id: 2, Name: "แยก" },
    { Id: 3, Name: "รวม" },
    { Id: 4, Name: "ภาษี 0" },
  ],
  key_type_items: {
    1: "อื่นๆ",
    2: "วัสดุ",
    3: "ค่าแรง",
    4: "ค่าเช่าเครื่องจักร",
    5: "หมายเหตุ",
    6: "สินทรัพย์",
  },
  type_items: [
    { Id: 1, Name: "อื่นๆ" },
    { Id: 2, Name: "วัสดุ" },
    { Id: 3, Name: "ค่าแรง" },
    { Id: 4, Name: "ค่าเช่าเครื่องจักร" },
    { Id: 5, Name: "หมายเหตุ" },
    { Id: 6, Name: "สินทรัพย์" },
  ],
};
const dateFormat = "DD/MM/YYYY";
const tokenweb = "3d9cc2eae16cb7a60bcbda71bdb44bca1e68e0b0";
const Agent = "nojw";

const App_LG = {
  th: {
    menu: {
      home: "หน้าแรก",
      casino: "คาสิโน",
      sport: "กีฬา",
      game: "เกมส์",
      slot: "สล๊อต",
    },
    btn: {
      register: "สมัครสมาชิกใหม่",
      deposit: "ฝากเงิน",
      withdraw: "ถอนเงิน",
      signin: "เข้าสู่ระบบ",
    },
    txt: {
      mobile: "ป้อนเบอร์มือถือ (ไม่ต้องใส่ -)",
    },
  },
  en: {
    menu: {
      home: "Home",
      casino: "Casino",
      sport: "Sportbook",
      game: "E-Games",
      slot: "Slot",
    },
    btn: {
      register: "Register",
      deposit: "Deposit",
      withdraw: "Withdraw",
      signin: "Login",
    },
    txt: {
      mobile: "Please enter mobile",
    },
  },
};

export {
  API_URL,
  APP_TEXT,
  pageSize,
  dateFormat,
  tokenweb,
  Agent,
  App_LG,
  getWindowDimensions,
  addCommas,
  WEB_TOKEN,
  ENDPOINT,
};

// https://api-v2.thaibulksms.com/sms
// API KEY SMS = 5013fee27cd5617415c1e595a19cdf9e
// API Secret SMS = f6771f3cbbc135ad21c8f7c2368ca6be

/*
var request = require('request');
var options = {
  'method': 'POST',
  'url': 'https://api-v2.thaibulksms.com/sms',
  'headers': {
    'Content-Type': 'application/json',
    'Authorization': 'Basic NTAxM2ZlZTI3Y2Q1NjE3NDE1YzFlNTk1YTE5Y2RmOWU6ZjY3NzFmM2NiYmMxMzVhZDIxYzhmN2MyMzY4Y2E2YmU='
  },
  body: JSON.stringify({"msisdn":"0898317144","message":"ทดสอบ sms","sender":"SMS.","force":"standard"})

};
request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
});


{
    "error": {
        "code": 100,
        "name": "ERROR_AUTHENTICATION_FAILED",
        "description": "Authentication failed."
    }
}

*/
