import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import Deposit from "./Deposit";
import DepositWait from "./DepositWait";

export default function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/pending`} component={DepositWait}></Route>
      <Route path={`${path}/`} component={Deposit}></Route>
    </Switch>
  );
}
