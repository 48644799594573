import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GroupIcon from "@material-ui/icons/Group";
import Container from "@material-ui/core/Container";
import LanguageIcon from "@material-ui/icons/Language";
import Typography from "@material-ui/core/Typography";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import Button from "@material-ui/core/Button";
import MoneyIcon from "@material-ui/icons/Money";
import Grid from "@material-ui/core/Grid";
import Tab1 from "./Aff1";
import Tab4 from "./Aff4";
import Tab3 from "./Aff3";
import Tab2 from "./Aff2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MovingText from "react-moving-text";

export default function FullWidthTabs(props) {
  const { t } = useTranslation();
  const [tab, setValue] = React.useState(4);

  const changeTap = (tab) => {
    setValue(tab);
  };

  const [copied, setcopied] = React.useState(false);

  const setCopyall = () => {
    setcopied(true);
  };
  const { items } = useSelector((store) => store.member);

  return (
    <>
      <div className="headpaddingtop"></div>
      <Container maxWidth="md">
        <div style={{ height: 30 }}></div>
        <Typography
          gutterBottom
          variant="h5"
          component="h5"
          style={{ textAlign: "center" }}
          className="headaff"
        >
          {t("reccommend.label")} รับ{" "}
          <font color="#e8a608">{items?.agent?.affper}% </font>จาก{" "}
          {items?.agent?.afftype == 1 ? "ยอดฝาก" : "ยอดเล่น"}
        </Typography>
        <div className="affline"></div>
        <div style={{ height: 20 }}></div>

        <div class="container topcontain">
          <img src="/images/affiliate2.png" height="80"></img>
          <div
            style={{
              textAlign: "center",
              color: "#000",
              height: 40,
              fontSize: 14,
            }}
          >
            รหัสแนะนำ{" "}
            <CopyToClipboard
              text={`${items?.agent?.url}/signup?aff=${items?.affcode}`}
              onCopy={() => setCopyall()}
            >
              <a className="btn-gradaff">คัดลอกลิ้งค์</a>
            </CopyToClipboard>
            {copied ? (
              <span style={{ textAlign: "right", color: "#e8a608" }}></span>
            ) : null}
          </div>
          <div className={copied ? "affbgacc" : "affbg"}>
            {items?.agent?.url}/signup?aff={items?.affcode}
          </div>

          <ul className="phome">
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              รับ 8% จากยอดเล่นสมาชิก
            </li>
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ฝาก ถอน ไม่ติดเทิร์น
            </li>
            <li>
              <img
                src="/images/them1/checkbox.png"
                height="35"
                className="m7889"
              ></img>
              ถอนได้ไม่ติดเทิร์น
            </li>
          </ul>
        </div>

        <div style={{ height: 10 }}></div>

        <div style={{ height: 10 }}></div>
        <ul className="aff">
          <li className={tab == 4 && "active"} onClick={() => changeTap(4)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <LanguageIcon />
              ภาพรวม
            </Grid>
          </li>
          <li className={tab == 1 && "active"} onClick={() => changeTap(1)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <GroupIcon />
              สมาชิก
            </Grid>
          </li>
          {/*
<li className={tab == 2 && "active"} onClick={() => changeTap(2)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <MoneyIcon />
              รายได้
            </Grid>
          </li>
            */}

          <li className={tab == 3 && "active"} onClick={() => changeTap(3)}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <AccountBalanceWalletIcon />
              ถอนรายได้
            </Grid>
          </li>
        </ul>
        <div style={{ height: 10 }}></div>
        {tab == 1 && <Tab1 type={items?.agent?.afftype} />}
        {tab == 4 && <Tab4 type={items?.agent?.afftype} />}
        {tab == 2 && <Tab2 type={items?.agent?.afftype} />}
        {tab == 3 && <Tab3 type={items?.agent?.afftype} />}
      </Container>
      <div style={{ height: 120 }}></div>
    </>
  );
}
