import React from "react";
import { useDispatch } from "react-redux";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import * as memberActions from "redux/member/action";

const CountdownTimer = ({ betlist, numbertype }) => {
  const dispatch = useDispatch();
  const handleamount = (item, e) => {
    let items = {
      ...item,
      betamount: e.target.value,
      delitem: 0, //  ประเภทแก้ไข
      numbertype,
    };
    const memdispatch = memberActions.handleamount(items);
    dispatch(memdispatch);
  };

  const handleremoveitem = (item) => {
    let items = {
      ...item,
      delitem: 1, // ประเภทลบข้อมูล
      numbertype,
    };
    const memdispatch = memberActions.handleamount(items);
    dispatch(memdispatch);
  };
  return (
    <div style={{ paddingBottom: 0 }}>
      <ul class="responsive-table">
        {betlist && betlist.length > 0 && (
          <li class="table-header">
            <div class="col hcol-1">#</div>
            <div class="col hcol-2">{betlist[0].name}</div>
            <div class="col hcol-3">อัตราคูณ</div>
            <div class="col hcol-4">เรทชนะ</div>
          </li>
        )}
        {betlist &&
          betlist.map((x, index) => {
            const classrow =
              x.remain == 0 || x.active == 0 ? "table-row-red" : "table-row";
            const rate = x.remain == 0 ? "ปิดรับ" : x.rate;

            return (
              <li class={classrow} key={`r3t${index}`}>
                <div class="col col-1">{++index}</div>
                <div class="col col-2">
                  <div className={`lotto_btn_2_active_rate`}>{x.number}</div>
                </div>
                <div class="col col-3">
                  <Tooltip
                    title={`เหลือยอดแทง ${x.remain}`}
                    placement="top-start"
                  >
                    <input
                      className="txtnumber"
                      placeholder={x.amount}
                      type="number"
                      value={x.amount}
                      disabled={x.remain == 0 && true}
                      onChange={(e) => handleamount(x, e)}
                    />
                  </Tooltip>
                </div>
                <div class="col col-4">{rate}</div>
                <div class="col col-5">
                  <input className="txtnumber" value={x.payout} disabled />
                </div>
                <div class="col col-6">
                  <div
                    className={`lotto_btn_3_active_del`}
                    onClick={() => handleremoveitem(x)}
                  >
                    <DeleteForeverIcon className="mverti" />
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default CountdownTimer;
