import React from "react";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useFetch from "lib/useFetch";
import { API_URL, addCommas } from "modules/configApp";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  img: {
    flexGrow: 1,
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  rootbg: {
    backgroundColor: "#171414",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const showmoney = (credit) => {
    if (credit > 0) {
      return (
        <font color="green">+{addCommas(parseFloat(credit).toFixed(2))}</font>
      );
    } else {
      return (
        <font color="red">{addCommas(parseFloat(credit).toFixed(2))}</font>
      );
    }
  };

  const checkwinloss = useFetch(API_URL + "/bet/1/winlossaff", {}, "get");

  return (
    <div className="topcontain">
      <ul className="dashboard">
        <li>
          <div className="boxdashboard">
            <div className="dashline">ยอดเล่นสมาชิกแนะนำ</div>
            <span className="bnxm2">
              {checkwinloss?.memberwinloss
                ? showmoney(checkwinloss?.memberwinloss)
                : 0}
            </span>
          </div>
        </li>
        <li>
          <div className="boxdashboard">
            <div className="dashline">ยอดรายได้</div>
            <span className="bnxm2">
              {checkwinloss?.winloss ? showmoney(checkwinloss?.winloss) : 0}
            </span>
          </div>
        </li>
        <li>
          <div className="boxdashboard">
            <div className="dashline">สมาชิกแนะนำ</div>
            <span className="bnxm2">{checkwinloss?.total}</span>
          </div>
        </li>
        <li>
          <div className="boxdashboard">
            <div className="dashline">จำนวนคลิกลิ้งค์ </div>
            <span className="bnxm2">{checkwinloss?.totalclick}</span>
          </div>
        </li>
      </ul>
    </div>
  );
}
