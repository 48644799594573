import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import PORoutes from "modules/purchase/po/components/Routes";
import dashboardRoutes from "modules/dashboard/components/Routes";
import transferRoutes from "modules/transfer/Routes";
import withdrawRoutes from "modules/withdraw/Routes";
import memberRoutes from "modules/member/Routes";
import casinoRoutes from "modules/casino/Routes";
import chatRoutes from "modules/chat/Routes";
import logingameRoutes from "modules/logingame/Routes";
import Signin from "./Signin2";
import Index from "./Index";
import Register from "./Register";
import RegisterNew from "./RegisterNew";
import Forgotpass from "./Forgotpass";
import RegisterStep2 from "./RegisterStep2";
import RegisterStep3 from "./RegisterStep3";
import NotFoundComponent from "./NotFoundComponent";
import Promotion from "./Promotion";

import * as loginActions from "redux/signin/action";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Signin {...props} />
      )
    }
  />
);

const IndexRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Index {...props} />
      )
    }
  />
);

const RegisteRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Register {...props} />
      )
    }
  />
);

const ForgotpassRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <Forgotpass {...props} />
      )
    }
  />
);

const RegisteRouteStep2 = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <RegisterStep2 {...props} />
      )
    }
  />
);

const RegisteRouteStep3 = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <RegisterStep3 {...props} />
      )
    }
  />
);

const RegisteNewRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      // ternary condition
      loginActions.isLoggedIn() ? (
        <Redirect to="/dashboard" />
      ) : (
        <RegisterNew {...props} />
      )
    }
  />
);

const PromotionRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Promotion {...props} />} />
);

export default function Content() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    //console.log("App created");

    dispatch(loginActions.reLogin());
  }, [dispatch]);
  return (
    <Switch>
      <PrivateRoute path="/logingame" component={logingameRoutes} />
      <PrivateRoute path="/deposit" component={transferRoutes} />
      <PrivateRoute path="/withdraw" component={withdrawRoutes} />
      <PrivateRoute path="/member" component={memberRoutes} />
      <PrivateRoute path="/chat" component={chatRoutes} />
      <PrivateRoute path="/po" component={PORoutes} />
      <PrivateRoute path="/dashboard" component={dashboardRoutes} />
      <PrivateRoute path="/play" component={casinoRoutes} />
      <LoginRoute path="/signin" component={Signin} />
      <RegisteNewRoute path="/signup" component={RegisteNewRoute} />
      <RegisteRoute path="/register" component={Register} />
      <PromotionRoute path="/promotion" component={Promotion} />
      <ForgotpassRoute path="/forgotpass" component={Forgotpass} />
      <RegisteRouteStep2 path="/otp" component={RegisterStep2} />
      <RegisteRouteStep3 path="/registerconfirm" component={RegisterStep3} />
      {/*
  <IndexRoute path="/home" component={Index} />
      <IndexRoute exact path="/" component={Index} />
        */}

      <LoginRoute exact path="/" component={Signin} />
      <Route component={NotFoundComponent} />
    </Switch>
  );
}
