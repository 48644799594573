import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//import Grid from "@material-ui/core/Grid";
import axios from "axios";
import { API_URL } from "modules/configApp";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import useFetch from "lib/useFetch";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

//import Grid from '@material-ui/core/Grid'
import { red } from "@material-ui/core/colors";

//import { emphasize, withStyles } from "@material-ui/core/styles";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import Chip from "@material-ui/core/Chip";
//import HomeIcon from "@material-ui/icons/Home";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DialogContent from "@material-ui/core/DialogContent";

import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
//import history from "history";
//import "./indexall.css";
import * as memberActions from "redux/member/action";

function createMarkup(data) {
  return { __html: data };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  rootCard: {
    width: "100%",
  },
  media: {
    height: 100,
  },
  mediaHead: {
    height: 180,
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
  },
}));

export default function AutoGrid() {
  const classes = useStyles();
  const { data, items } = useSelector((store) => store.member);
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [opens, setOpens] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleCloses = () => {
    setOpens(false);
  };

  const goPage = (page) => {
    history.push(page);
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    //dispatch(action2);
  }, []);

  const loginqtech = async () => {
    setloadspin(true);
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/qtechgetlobbyurl`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    const sss = link.replace("zh-CN", "th-TH");
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = sss;
    //a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
    setloadspin(false);
    //window.open(link);
  };

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  const { items: item } = useSelector((store) => store.member);

  const promotion = useFetch(API_URL + "/promotion/1/deposit", {}, "get");

  return (
    <div className="hmssssx">
      <div style={{ textAlign: "center" }}>
        <img src="/images/promotion.png" height="110"></img>
      </div>
      <div className="mssssx">
        {" "}
        {/*
 <Container maxWidth="sm">
        <Card className={classes.headpaper}>
          <Breadcrumbs className={classes.paper} aria-label="breadcrumb">
            <StyledBreadcrumb
              component="a"
              href="#"
              label="คาสิโนออนไลน์"
              icon={<HomeIcon fontSize="small" />}
              onClick={handleClick}
            />
            <StyledBreadcrumb
              component="a"
              href="#"
              label="เลือกเดิมพัน"
              onClick={clickopen}
            />
          </Breadcrumbs>
        </Card>
      </Container>
        */}
        <div style={{ height: 30 }}></div>
        <Container maxWidth="md">
          {loadspin && (
            <div className="divLoading">
              <CircularProgress color="secondary" />
            </div>
          )}
          <div className={classes.root}>
            {promotion.item &&
              [].map((x, index) => (
                <>
                  <div key={index} style={{ textAlign: "center" }}>
                    <img
                      //className={classes.mediaHead}
                      src={`${x.proexten},${x.prophoto}`}
                      className="imgpro"
                    />
                    <div style={{ fontSize: 36, color: "#fff" }}>{x.name}</div>

                    <div
                      style={{ fontSize: 16, color: "#fff", textAlign: "left" }}
                      dangerouslySetInnerHTML={createMarkup(x.description)}
                    />
                  </div>
                  <div className="linepro"></div>
                  <div style={{ height: 30 }}></div>
                </>
              ))}

            <div style={{ height: 10 }}></div>

            <div style={{ height: 10 }}></div>

            <div style={{ height: 10 }}></div>
          </div>
          <div style={{ textAlign: "center" }}>
            <img src="/images/pro111.png" className="imgpro"></img>
          </div>
          <div style={{ fontSize: 40, textAlign: "center" }}>
            สล็อต โบนัสฟรี 100%
          </div>
          <br></br>
          <div>
            {" "}
            <b>ชนิดเกมส์</b> : สล็อตเท่านั้น**
          </div>
          <br></br>
          <Alert severity="error">
            **ในระหว่างที่สมาชิกรับโปรโมชั่น "สล็อต โบนัสฟรี 100%"
            ถ้าเล่นเกมส์อื่นที่ไม่ใช่สล็อต จะถูกระงับการถอน
            และปรับยอดเงินคงเหลือเป็นศูนย์
          </Alert>
          <br></br>
          <div>
            <div>
              {" "}
              <b>โบนัสฟรี</b> : 100%
            </div>
            <div>
              {" "}
              <b>เทิร์นโอเวอร์</b> : 20 เท่า
            </div>
            <div>
              {" "}
              <b>รับโบนัสขั้นต่ำ</b> : 50 บาท
            </div>
            <div>
              {" "}
              <b>รับโบนัสสูงสุด</b> : 1,000 บาท
            </div>
            <div>
              {" "}
              <b>ถอนสูงสุด</b> : 5 เท่าจากยอดฝาก
            </div>
            <br></br>
            ตัวอย่าง <br></br> ยอดฝาก 100 บาท <br></br>ได้รับโบนัส 100% = 100
            บาท <br></br>
            ยอดเงินคงเหลือ 10 บาท <br></br>
            ยอดเทิร์นที่ต้องทำ (100+100+10)x20เท่า = 4,200 บาท <br></br>{" "}
            <br></br>
            การคำนวณยอดเทิร์น <br></br> ถ้าหลังจากเดิมพัน <br></br>
            ยอดเครดิตมีมากขึ้น ยอดเทิร์นจะคิดจากยอดเดิมพัน <br></br>{" "}
            ยอดเครดิตมีน้อยลง ยอดเทิร์นจะคิดจากยอดเสีย <br></br>
            ยอดเครดิตมีเท่าเดิม ไม่คิดยอดเทิร์น <br></br> <br></br>
            การแก้เทิร์น <br></br> มี 2 วิธี ดังนี้ <br></br>1.
            ทำยอดเทิร์นโอเวอร์ให้ถึงตามเงื่อนไขที่โปรโมชั่นกำหนด <br></br> 2.
            เล่นเสียจนยอดเงินคงเหลือต่ำกว่า 5 บาท <br></br> <br></br>
            <div>วิธีการรับโปรโมชั่น</div>
            ตอนฝากเงิน ให้ลูกค้ากดรับโปรโมชั่นที่ต้องการ
          </div>
          <br></br>
          <div>
            <b>ข้อตกลงและเงื่อนไขเพิ่มเติม </b>
            <br></br>1.โปรโมชั่นนี้สามารถรับซ้ำได้<br></br>
            2. ในระหว่างที่สมาชิกรับโปรโมชั่น "สล็อต โบนัสฟรี 100%"
            ถ้าเล่นเกมส์อื่นที่ไม่ใช่สล็อต จะถูกระงับการถอน
            และปรับยอดเงินคงเหลือเป็นศูนย์<br></br>
            3. สมาชิกต้องทำการแก้เทิร์นก่อนถึงจะถอนเงินได้<br></br>
            4. สามารถซื้อฟรีสปินได้ระหว่างรับโปรโมชั่นนี้<br></br>
            5. การเดิมพัน ยกเลิก โมฆะ จะไม่ถูกนำมาคำนวณในโปรโมชั่นนี้<br></br>
            6. เบอร์โทรศัพท์, ชื่อ-นามสกุล, เลขบัญชีธนาคาร, ชื่อบัญชีธนาคาร, IP
            อินเตอร์เน็ต ที่ใช้ในการสมัคร หากตรวจสอบแล้วมีข้อมูลซ้ำกัน
            จะถูกระงับยอดเงินและโบนัสทันที<br></br>
            7. ในระหว่างที่ร่วมโปรโมชั่น ไม่สามารถแก้ไขข้อมูลใดๆได้ทั้งสิ้น เช่น
            ชื่อ นามสุกล ธนาคาร ชื่อบัญชีธนาคาร<br></br>
            8. ข้อกำหนดและเงื่อนไขมีผลบังคับใช้ทันที
            ทางเว็บมีสิทธิ์เปลี่ยนแปลงรายละเอียดของโปรโมชั่นนี้
            โดยไม่ต้องแจ้งให้สมาชิกทราบล่วงหน้า
          </div>
          <div style={{ height: 20 }}></div>
          <div className="linepro"></div>
          <div style={{ height: 70 }}></div>
          <div style={{ textAlign: "center" }}>
            <img src="/images/pro22.png" className="imgpro"></img>
          </div>
          <div style={{ fontSize: 40, textAlign: "center" }}>
            แนะนำเพื่อน รับ 5%
          </div>
          <br></br>
          <Alert severity="info">
            เพียงแค่ท่านชวนสมาชิกมาสมัคร
            จะได้รับค่าแนะนำจากยอดเสียของสมาชิกที่ท่านแนะนำมา
            สร้างรายได้กว่าเดือนละ 100,000 บาท ชวนเยอะยิ่งได้เยอะ
          </Alert>
          <br></br>
          <div>
            <br></br>
            ตัวอย่าง <br></br> ยูสเซอร์ A ฝากเงินเข้ามา 100 บาท รับโบนัสฟรี 100
            บาท <br></br>
            สมมุติว่า A เล่นเสียหมดเลย ยอดเสีย A จะเท่ากับ 200 บาท<br></br>{" "}
            <br></br>
            วิธีคำนวณค่าแนะนำ <br></br>
            (ยอดเสียA-โบนัสฟรีที่Aรับ)x5% ผู้แนะนำ A จะได้ค่าแนะนำ (200-100)x5%
            = 5 บาท (หากยอดค่าแนะนำติดลบ แปลว่าสมาชิกที่ท่านแนะนำมาได้กำไร)
          </div>
          <br></br>
          <div>
            ข้อตกลงและเงื่อนไขเพิ่มเติม <br></br>1. ค่าแนะนำคำนวณจาก ยอดเสีย
            จะอัพเดตตลอดหลังจากเดิมพันจบ ท่านสามารถกดรับค่าแนะนำที่ขั้นต่ำ 15
            บาท
            <br></br>2. หากสมาชิกที่ท่านแนะนำมามีบิลเดิมพัน ยกเลิกหรือโมฆะ
            ท่านจะไม่ได้ค่าแนะนำของบิลนั้น <br></br>3. เบอร์โทรศัพท์,
            ชื่อ-นามสกุล, เลขบัญชีธนาคาร, ชื่อบัญชีธนาคาร, IP อินเตอร์เน็ต
            ที่ใช้ในการสมัคร หากตรวจสอบแล้วมีข้อมูลซ้ำกัน
            จะถูกระงับการถอนเงินทันที<br></br>
            4.ข้อกำหนดและเงื่อนไขมีผลบังคับใช้ทันที
            ทางเว็บมีสิทธิ์เปลี่ยนแปลงรายละเอียดของโปรโมชั่นนี้
            โดยไม่ต้องแจ้งให้สมาชิกทราบล่วงหน้า
          </div>
          <div style={{ height: 20 }}></div>
          <div className="linepro"></div>
        </Container>
      </div>
      <div style={{ height: 180 }}></div>
    </div>
  );
}
