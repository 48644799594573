import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API_URL } from "modules/configApp";

import { useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 385,
    backgroundColor: "#fff",
  },
  rootLoading: {
    zIndex: 10,
    top: 180,
    margin: "0 auto",
    position: "absolute",
  },
  media: {
    height: 175,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AutoGrid() {
  const { data } = useSelector((store) => store.member);
  const classes = useStyles();
  const location = useLocation();
  const loginsbobet = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginsbobet`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
    //setloadspin(false);
    //window.open(link);
  };

  const logingevo = async () => {
    const {
      data: { link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loadevolution`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    //a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
  };

  const loginsbobetsexy = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginsbobetsexy`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
  };

  const loginsaba = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginsaba`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    //a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
  };

  const loginwwbet = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginwwbet`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    //a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
  };

  const loginevoplay = async (game) => {
    const {
      data: { link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/evoplaygamelogin`,
      params: { code: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
  };

  const loginjoker = async (game) => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginjoker`,
      params: { gamecode: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
  };

  const loginhabanero = async (game) => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginhabanero`,
      params: { gamekey: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
  };

  const loginpg = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginpg`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
  };

  const loginqtech = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/qtechgetlobbyurl`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });
    const sss = link.replace("zh-CN", "th-TH");
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = sss;
    a.click();
    document.body.removeChild(a);
  };

  const loginwmcasino = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginwm`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    a.click();
    document.body.removeChild(a);
  };

  const logindgcasino = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/logindg`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    a.click();
    document.body.removeChild(a);
  };

  const loginezuki = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginezuki`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    a.click();
    document.body.removeChild(a);
  };

  const loginhabanerolobby = async (game) => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginhabanerolobby`,
      params: { gamekey: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;

    a.click();
    document.body.removeChild(a);
  };

  const loginplaygame = async (game) => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/${game}/qtechgamelogin`,
      params: { gamecode: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    const sss = link.replace("zh-CN", "th-TH");
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = sss;
    a.click();
    document.body.removeChild(a);
  };

  const loginseamless = async (code) => {
    const {
      data: { url: link },
    } = await axios({
      //method: "post",
      method: "get",
      //url: `${API_URL}/bet/1/qtechgetlobbyurl`,
      //url: `${API_URL}/bet/qtechgamelist`,
      url: `${API_URL}/bet/1/loginsbobetgroup`,
      params: { gpid: code },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    const sss = link.replace("zh-CN", "th-TH");
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = sss;
    a.click();
    document.body.removeChild(a);
  };

  const logindg = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/logindg`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    a.click();
    document.body.removeChild(a);
  };

  const logingsa = async () => {
    const {
      data: { url: link },
    } = await axios({
      method: "get",
      url: `${API_URL}/bet/1/loginsa`,
      //params: { game: game },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("AdminToken")}`,
      },
    });

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = link;
    //a.target = "_blank";
    /* a.download = <your_fileName>; */
    a.click();
    document.body.removeChild(a);
  };

  React.useEffect(() => {
    const { search } = location;
    const gametype = search.split("=")[1];

    const url2 = `https://www.google.com${search}`;
    const url = new URL(url2);

    //const gametype = url.searchParams.get("type");
    const grouptype = url.searchParams.get("grouptype");
    const groupcode = url.searchParams.get("groupcode");

    if (grouptype == 1) {
      loginseamless(groupcode);
    } else {
      if (gametype.includes("sbobet")) {
        loginsbobet();
      }
      if (gametype.includes("evolution")) {
        logingevo();
      }
      if (gametype.includes("habanero")) {
        loginhabanerolobby();
      }

      //loginhabanerolobby
      if (gametype.includes("sexy")) {
        loginsbobetsexy();
      }
      if (gametype.includes("saba")) {
        loginsaba();
      }
      if (gametype.includes("wwbet")) {
        loginwwbet();
      }
      if (gametype.includes("sagame")) {
        logingsa();
      }

      if (gametype.includes("qtech")) {
        loginqtech();
      }

      if (gametype.includes("dg")) {
        logindg();
      }

      //loginqtech

      if (gametype.includes("pg")) {
        loginpg();
      }
      if (gametype.includes("evoplay")) {
        const game = search.split("=")[2];
        loginevoplay(game);
      }
      if (gametype.includes("joker")) {
        const game = search.split("=")[2];
        loginjoker(game);
      }
      if (gametype.includes("playgame")) {
        const game = search.split("=")[2];
        loginplaygame(game);
      }
    }
  }, []);

  return (
    <>
      <div className="mload">กำลังเข้าสู่เกมส์...</div>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}
