// ฟังชั่นเอาเฉพาะค่าล่าสุดท่านั้น
export function getnumberlast(
  initialState,
  state,
  action,
  betlist3t,
  type,
  tod3
) {
  // เช็คว่ามี 3 ตัวกลับมั้ย
  let newbetlist3t = [];
  const backnumber = initialState.find(
    (x) => x.active == true && x.backnumber == 1
  );
  // หา 3 ตัวบน ว่ามีมั้ย
  const numbertop31 = initialState.find(
    (x) => x.active == true && x.typerun == type
  );
  if (numbertop31) {
    const newnumber = `${state.number1}${state.number2}${action.payload}`;
    // เช็คว่าตรงกับเลขอั้นมั้ย

    let rate = numbertop31.rate;
    let remain = numbertop31.remain;
    let maxbet = numbertop31.maxbet;
    let minbet = numbertop31.minbet;

    const { numberlimit } = numbertop31;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );
    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist3t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      // แสดงว่ามีค่า
      newbetlist3t = [
        {
          name: numbertop31.name,
          typerun: type,
          number: newnumber,
          amount: minbet,
          rate,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        },
      ];
    } else {
      newbetlist3t = [
        {
          name: numbertop31.name,
          typerun: type,
          number: newnumber,
          rate,
          amount: minbet,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        },
      ];
    }

    if (backnumber) {
      // ถ้ามีกลับ
      const newarrnumber = tod3.find((x) => x.number.includes(newnumber));

      const newarray = [...new Set(newarrnumber.number)];
      const nemberlast = newarray.filter((x) => x != newnumber);
      for (let i = 0; i < nemberlast.length; i++) {
        newbetlist3t.push({
          name: numbertop31.name,
          typerun: type,
          number: nemberlast[i],
          rate,
          amount: minbet,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        });
      }
    }
    newbetlist3t.sort((a, b) => {
      return a.number - b.number;
    });
  }
  return newbetlist3t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumber(initialState, state, action, betlist3t, type, tod3) {
  // เช็คว่ามี 3 ตัวกลับมั้ย
  let newbetlist3t = [];
  const backnumber = initialState.find(
    (x) => x.active == true && x.backnumber == 1
  );
  // หา 3 ตัวบน ว่ามีมั้ย
  const numbertop31 = initialState.find(
    (x) => x.active == true && x.typerun == type
  );
  if (numbertop31) {
    const newnumber = `${state.number1}${state.number2}${action.payload}`;
    // เช็คว่าตรงกับเลขอั้นมั้ย

    let rate = numbertop31.rate;
    let remain = numbertop31.remain;
    let maxbet = numbertop31.maxbet;
    let minbet = numbertop31.minbet;

    const { numberlimit } = numbertop31;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );
    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist3t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      const data = betlist3t[index];
      // แสดงว่ามีค่า
      newbetlist3t = [
        ...betlist3t.splice(0, index),

        { ...data },
        {
          name: numbertop31.name,
          typerun: type,
          number: newnumber,
          amount: minbet,
          rate,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        },
        ...betlist3t.slice(index + 1),
      ];
    } else {
      newbetlist3t = [
        {
          name: numbertop31.name,
          typerun: type,
          number: newnumber,
          rate,
          amount: minbet,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        },
      ];
    }

    if (backnumber) {
      // ถ้ามีกลับ
      const newarrnumber = tod3.find((x) => x.number.includes(newnumber));

      const newarray = [...new Set(newarrnumber.number)];
      const nemberlast = newarray.filter((x) => x != newnumber);
      for (let i = 0; i < nemberlast.length; i++) {
        newbetlist3t.push({
          name: numbertop31.name,
          typerun: type,
          number: nemberlast[i],
          rate,
          amount: minbet,
          remain,
          maxbet,
          minbet,
          payout: parseFloat(payout).toFixed(0),
        });
      }
    }
    newbetlist3t.sort((a, b) => {
      return a.number - b.number;
    });
  }
  return newbetlist3t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumbertod3(initialState, state, action, betlist3t, type) {
  let newbetlist3t = [];

  // หา 3 ตัวบน ว่ามีมั้ย
  const numbertop31 = initialState.find(
    (x) => x.active == true && x.tode == type
  );
  if (numbertop31) {
    const newnumber = `${state.number1}${state.number2}${action.payload}`;

    let rate = numbertop31.rate;
    let remain = numbertop31.remain;
    let maxbet = numbertop31.maxbet;
    let minbet = numbertop31.minbet;

    const { numberlimit } = numbertop31;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );
    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist3t.findIndex((x) => x.tode == type);
    if (index !== -1) {
      const data = betlist3t[index];
      // แสดงว่ามีค่า
      newbetlist3t = [
        ...betlist3t.splice(0, index),

        { ...data },
        {
          name: numbertop31.name,
          tode: type,
          number: newnumber,
          amount: numbertop31.minbet,
          payout,
          maxbet,
          rate,
        },
        ...betlist3t.slice(index + 1),
      ];
    } else {
      newbetlist3t = [
        {
          name: numbertop31.name,
          tode: type,
          number: newnumber,
          amount: numbertop31.minbet,
          payout,
          maxbet,
          rate,
        },
      ];
    }

    newbetlist3t.sort((a, b) => {
      return a.number - b.number;
    });
  }
  return newbetlist3t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumbet2t(lottotypeitems, betlist2t, state, action, type) {
  const backnumber = lottotypeitems.find(
    (x) => x.active == true && x.backnumber == 1
  );

  // หา 2 บน ว่ามีมั้ย
  let newbetlist2t = [];
  const itemruntop = lottotypeitems.find(
    (x) => x.active == true && x.typerun == type
  );

  if (itemruntop) {
    let rate = itemruntop.rate;
    let remain = itemruntop.remain;
    let maxbet = itemruntop.maxbet;
    let minbet = itemruntop.minbet;
    let newnumber = `${state.number1}${action.payload}`;

    const { numberlimit } = itemruntop;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );

    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist2t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      const data = betlist2t[index];
      // แสดงว่ามีค่า
      newbetlist2t = [
        ...betlist2t.splice(0, index),

        { ...data },
        {
          name: itemruntop.name,
          typerun: type,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
          number: `${state.number1}${action.payload}`,
        },
        ...betlist2t.slice(index + 1),
      ];
    } else {
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          number: `${state.number1}${action.payload}`,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        },
      ];
    }

    if (backnumber) {
      // ถ้ามีกลับ
      const newnumber = `${state.number1}${action.payload}`;
      const backumbernew = flipInt(newnumber);

      if (backumbernew != newnumber) {
        newbetlist2t.push({
          name: itemruntop.name,
          typerun: type,
          number: backumbernew,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        });
      }
    }

    newbetlist2t.sort((a, b) => {
      return a.number - b.number;
    });
  }

  return newbetlist2t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumbet2tlast(
  lottotypeitems,
  betlist2t,
  state,
  action,
  type
) {
  const backnumber = lottotypeitems.find(
    (x) => x.active == true && x.backnumber == 1
  );

  // หา 2 บน ว่ามีมั้ย
  let newbetlist2t = [];
  const itemruntop = lottotypeitems.find(
    (x) => x.active == true && x.typerun == type
  );

  if (itemruntop) {
    let rate = itemruntop.rate;
    let remain = itemruntop.remain;
    let maxbet = itemruntop.maxbet;
    let minbet = itemruntop.minbet;
    let newnumber = `${state.number1}${action.payload}`;

    const { numberlimit } = itemruntop;
    const checknumberlimit = numberlimit.find((x) =>
      x.number.includes(newnumber)
    );

    if (checknumberlimit) {
      rate = checknumberlimit.rate;
      remain = checknumberlimit.remain;
    }

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist2t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      // แสดงว่ามีค่า
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
          number: `${state.number1}${action.payload}`,
        },
      ];
    } else {
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          number: `${state.number1}${action.payload}`,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        },
      ];
    }

    if (backnumber) {
      // ถ้ามีกลับ
      const newnumber = `${state.number1}${action.payload}`;
      const backumbernew = flipInt(newnumber);

      if (backumbernew != newnumber) {
        newbetlist2t.push({
          name: itemruntop.name,
          typerun: type,
          number: backumbernew,
          rate: rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        });
      }
    }

    newbetlist2t.sort((a, b) => {
      return a.number - b.number;
    });
  }

  return newbetlist2t.map((x, index) => ({ ...x, no: ++index }));
}

export function getnumbetrt(lottotypeitems, betlist2t, state, action, type) {
  // หา 2 บน ว่ามีมั้ย
  let newbetlist2t = [];
  const itemruntop = lottotypeitems.find(
    (x) => x.active == true && x.typerun == type
  );

  let newnumber = `${action.payload}`;
  const checkdubplicate = betlist2t.find((x) => x.number == newnumber);

  if (itemruntop) {
    let rate = itemruntop.rate;
    let remain = itemruntop.remain;
    let maxbet = itemruntop.maxbet;
    let minbet = itemruntop.minbet;

    let payout = parseFloat(minbet) * parseFloat(rate);
    if (remain == 0) {
      payout = 0;
      minbet = 0;
    }

    const index = betlist2t.findIndex((x) => x.typerun == type);
    if (index !== -1) {
      const data = betlist2t[index];
      // แสดงว่ามีค่า

      newbetlist2t = [
        ...betlist2t.splice(0, index),

        { ...data },
        {
          name: itemruntop.name,
          typerun: type,
          rate: itemruntop.rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
          number: `${action.payload}`,
        },
        ...betlist2t.slice(index + 1),
      ];
    } else {
      newbetlist2t = [
        {
          name: itemruntop.name,
          typerun: type,
          number: `${action.payload}`,
          rate: itemruntop.rate,
          minbet: itemruntop.minbet,
          maxbet: itemruntop.maxbet,
          payout,
          remain,
          amount: minbet,
        },
      ];
    }

    newbetlist2t.sort((a, b) => {
      return a.number - b.number;
    });
    if (checkdubplicate) {
      newbetlist2t = betlist2t;
    }
  }

  return newbetlist2t.map((x, index) => ({ ...x, no: ++index }));
}

export function flipInt(str) {
  var splitString = str.split("");
  var reverseArray = splitString.reverse();
  var joinArray = reverseArray.join("");
  return joinArray; // "olleh"
}
