import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useHistory, useParams } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
//import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Container from "@material-ui/core/Container";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { red } from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from "@material-ui/icons/Error";
import * as memberActions from "redux/member/action";

const CountdownTimer = ({ removeall }) => {
  const dispatch = useDispatch();
  const {
    data,
    items,
    isLoadinggame: isLoading,
    itemsgame,
    count,
    numbertype,
    number1,
    number2,
    number3,
    lottotypeitems,
    lottototal,
    addlottototal,
    betamount,
    lottobetlist,
    btnclickrate,
    betlist3t,
    lottonotify,
    betlist3tod,
    betlist3f,
    betlist3l,
    betlist2t,
    betlist2l,
    betlistrt,
    betlistrl,
    tabcontent,
    BtnHead3,
  } = useSelector((store) => store.member);
  const { tabmain, tab1, tab2 } = tabcontent;

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleCloseScrool = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  React.useEffect(() => {
    const action2 = memberActions.GetCredit({});
    dispatch(action2);
  }, []);

  //qtechgetlobbyurl

  const [loadspin, setloadspin] = React.useState(false);

  React.useEffect(() => {
    const action = memberActions.GameAllList(4);
    dispatch(action);
  }, []);

  const { items: item } = useSelector((store) => store.member);
  const showmenu = (menu) => {
    const action = memberActions.GameAllList(menu);
    dispatch(action);
  };

  const checktypegame = (type, index) => {
    return (
      <div class="xribbon2 xribbon-top-right">
        <span>ปิดรับ 14:00</span>
      </div>
    );
  };

  const changeitemrate = (dataitems) => {
    const datadiscc = memberActions.handlelottoRate(dataitems);
    dispatch(datadiscc);
  };

  const handleDelete = (item) => {
    const datadiscc = memberActions.delhandlelottoRate(item);
    dispatch(datadiscc);
  };
  const checkclass = (data) => {
    if (data.active == true) {
      return "_active";
    } else {
      return "";
    }
  };
  const showselectlist = () => {
    const xxx = lottotypeitems
      .filter((x) => x.active == true)
      .map((list, index) => {
        let color = null;
        if (list.numbertype == 3) {
          color = "#F50157";
        }
        if (list.numbertype == 2) {
          color = "#1381e7";
        }
        if (list.numbertype == 0) {
          color = "#0cc981";
        }
        return (
          <Chip
            key={index}
            label={list.name}
            size="small"
            onDelete={() => handleDelete(list)}
            style={{ backgroundColor: color, color: "#fff" }}
          />
        );
      });
    return xxx;
  };

  React.useEffect(() => {
    const handleEsc = (event) => {
      if (
        event.keyCode == 48 ||
        event.keyCode == 49 ||
        event.keyCode == 50 ||
        event.keyCode == 51 ||
        event.keyCode == 52 ||
        event.keyCode == 53 ||
        event.keyCode == 54 ||
        event.keyCode == 55 ||
        event.keyCode == 56 ||
        event.keyCode == 57
      ) {
        additems(event.keyCode);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const additems = (key) => {
    const datanumber = {
      48: 0,
      49: 1,
      50: 2,
      51: 3,
      52: 4,
      53: 5,
      54: 6,
      55: 7,
      56: 8,
      57: 9,
    };

    if (!btnclickrate) {
      const dispacrj = memberActions.addcountter(datanumber[key]);
      dispatch(dispacrj);
    }
  };

  const addnumberbybtn = (number) => {
    const dispacrj = memberActions.addcountter(number);
    dispatch(dispacrj);
  };

  const handlebtnclickrate = () => {
    const dispacrj = memberActions.btnclickrate();
    dispatch(dispacrj);
  };

  const showboxnumber = () => {
    const data = lottotypeitems.filter(
      (x) => x.active == true && x.backnumber == 0
    );
    if (data.length > 0) {
      if (numbertype == 3) {
        return (
          <>
            <div>ระบุตัวเลข</div>
            <div className="lotto_box_number">
              <span>{number1}</span>
              <span>{number2}</span>
              <span>{number3}</span>
            </div>
          </>
        );
      }
      if (numbertype == 2) {
        return (
          <>
            <div>ระบุตัวเลข</div>
            <div className="lotto_box_number">
              <span>{number1}</span>
              <span>{number2}</span>
            </div>
          </>
        );
      }
      if (numbertype == 0) {
        return (
          <>
            <div>ระบุตัวเลข</div>
            <div className="lotto_box_number">
              <span>{number1}</span>
            </div>
          </>
        );
      }
    }
  };
  /*
  const removeall = () => {
    handleClickOpen2(true);
  };
  */

  const goTablotto = (tab) => {
    const memberdispacth = memberActions.handleTablotto(tab);
    dispatch(memberdispacth);
  };

  const btnclearnumber = () => {
    const memberdispacth = memberActions.btnclearnumber();
    dispatch(memberdispacth);
  };

  const handleBtnHead3 = (numbertext) => {
    const memberdispacth = memberActions.handleBtnHead3(numbertext);
    dispatch(memberdispacth);
  };

  const handleBtnHeadRl3 = (numbertext) => {
    const memberdispacth = memberActions.handleBtnHeadRl3(numbertext);
    dispatch(memberdispacth);
  };

  const handlecheckbox = (type, numbertext) => {
    const memberdispacth = memberActions.handlecheckbox(type, numbertext);
    dispatch(memberdispacth);
  };

  const showbuttom = () => {
    const data = lottotypeitems.filter(
      (x) => x.active == true && x.backnumber == 0
    );
    if (data.length > 0) {
      return (
        <ul className="lotto_press_btn">
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(1)}>
              1
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(2)}>
              2
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(3)}>
              3
            </div>
          </li>
          <li>
            <div className="btn_del" onClick={() => btnclearnumber()}>
              <BackspaceIcon className="mverti" />
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(4)}>
              4
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(5)}>
              5
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(6)}>
              6
            </div>
          </li>
          <li>
            <div className="btn_cancel" onClick={() => handleclearlast()}>
              ยกเลิกล่าสุด
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(7)}>
              7
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(8)}>
              8
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(9)}>
              9
            </div>
          </li>
          <li>
            <div className="btn_remove" onClick={() => removeall()}>
              ล้างข้อมูล
            </div>
          </li>
          <li>
            <div className="btn_random" onClick={() => handlenumberrandom()}>
              สุ่มตัวเลข
            </div>
          </li>
          <li>
            <div className="btn_calcula" onClick={() => addnumberbybtn(0)}>
              0
            </div>
          </li>
          <li>
            <div className="btn_bank">&nbsp;</div>
          </li>
        </ul>
      );
    }
  };

  // index = ตำแหน่ง 0, numbertype = เลขกี่หลัก
  const shownumberlong = (index, numbertype, number) => {
    if (numbertype == 3) {
      const newnumber = `${number}`;

      if (newnumber.length == 1) {
        const textnumber = `${index}0${newnumber}`;
        let classnamebtn = "btnnumber3";

        // เช็คว่ามีเลขอั้นมั้ย
        // typerun = 31 คือ 3ตัวบน
        // typerun = 32 คือ 3ตัวโต๊ด
        // typerun = 33 คือ 3ตัวหน้า
        // typerun = 34 คือ 3ตัวล่าง
        const numbertop31 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 31
        );

        if (numbertop31) {
          const { numberlimit } = numbertop31;
          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }

        const numbertop32 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 32
        );

        if (numbertop32) {
          const { numberlimit } = numbertop32;

          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }

        const checknumber = betlist3t.find((x) => x.number == textnumber);

        if (checknumber) {
          classnamebtn = "btnnumber3_active";
        }
        const checknumber3tod = betlist3tod.find((x) => x.number == textnumber);

        if (checknumber3tod) {
          classnamebtn = "btnnumber3_active";
        }
        const checknumber3l = betlist3l.find((x) => x.number == textnumber);

        if (checknumber3l) {
          classnamebtn = "btnnumber3_active";
        }
        const checknumber3f = betlist3f.find((x) => x.number == textnumber);

        if (checknumber3f) {
          classnamebtn = "btnnumber3_active";
        }

        return (
          <div
            className={classnamebtn}
            onClick={() => handlenumberfix(textnumber)}
          >
            {textnumber}
          </div>
        );
      } else {
        const textnumber = `${index}${newnumber}`;
        let classnamebtn = "btnnumber3";

        const numbertop31 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 31
        );

        if (numbertop31) {
          const { numberlimit } = numbertop31;
          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }

        const numbertop32 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 32
        );

        if (numbertop32) {
          const { numberlimit } = numbertop32;

          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }
        const checknumber = betlist3t.find((x) => x.number == textnumber);

        if (checknumber) {
          classnamebtn = "btnnumber3_active";
        }
        const checknumber3tod = betlist3tod.find((x) => x.number == textnumber);

        if (checknumber3tod) {
          classnamebtn = "btnnumber3_active";
        }
        const checknumber3l = betlist3l.find((x) => x.number == textnumber);

        if (checknumber3l) {
          classnamebtn = "btnnumber3_active";
        }
        const checknumber3f = betlist3f.find((x) => x.number == textnumber);

        if (checknumber3f) {
          classnamebtn = "btnnumber3_active";
        }
        return (
          <div
            className={classnamebtn}
            onClick={() => handlenumberfix(textnumber)}
          >
            {textnumber}
          </div>
        );
      }
    } else if (numbertype == 2) {
      const newnumber = `${number}`;

      if (newnumber.length == 1) {
        const textnumber = `0${newnumber}`;
        const checknumber = betlist2t.find((x) => x.number == textnumber);
        const checknumber2l = betlist2l.find((x) => x.number == textnumber);
        let classnamebtn = "btnnumber3";

        // เช็คว่ามีเลขอั้นมั้ย
        // typerun = 31 คือ 3ตัวบน
        // typerun = 32 คือ 3ตัวโต๊ด
        // typerun = 33 คือ 3ตัวหน้า
        // typerun = 34 คือ 3ตัวล่าง
        // typerun = 3 คือ 2 ตัวบน
        // typerun = 4 คือ 2ตัวล่าง
        const numbertop31 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 3
        );

        if (numbertop31) {
          const { numberlimit } = numbertop31;
          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }

        const numbertop32 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 4
        );

        if (numbertop32) {
          const { numberlimit } = numbertop32;

          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }

        if (checknumber2l) {
          classnamebtn = "btnnumber3_active";
        }
        if (checknumber) {
          classnamebtn = "btnnumber3_active";
        }

        return (
          <div
            className={classnamebtn}
            onClick={() => handlenumberfix(textnumber)}
          >
            {textnumber}
          </div>
        );
      } else {
        const textnumber = `${newnumber}`;
        let classnamebtn = "btnnumber3";
        const checknumber = betlist2t.find((x) => x.number == textnumber);
        const checknumber2l = betlist2l.find((x) => x.number == textnumber);

        const numbertop31 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 3
        );

        if (numbertop31) {
          const { numberlimit } = numbertop31;
          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }

        const numbertop32 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 4
        );

        if (numbertop32) {
          const { numberlimit } = numbertop32;

          const checknumberlimit = numberlimit.find((x) =>
            x.number.includes(textnumber)
          );

          if (checknumberlimit) {
            //rate = checknumberlimit.rate;
            //remain = checknumberlimit.remain;
            if (checknumberlimit.remain == 0) {
              classnamebtn = "btnnumber3_close";
            } else {
              classnamebtn = "btnnumber3_paylimit";
            }
          }
        }
        if (checknumber) {
          classnamebtn = "btnnumber3_active";
        }
        if (checknumber2l) {
          classnamebtn = "btnnumber3_active";
        }

        return (
          <div
            className={classnamebtn}
            onClick={() => handlenumberfix(textnumber)}
          >
            {textnumber}
          </div>
        );
      }
    } else {
    }
  };

  const handlenumberfix = (numbertext) => {
    const memberdispacth = memberActions.handlenumberfix(numbertext);
    dispatch(memberdispacth);
  };

  const handlenumberrandom = () => {
    let numbertext = "980";
    let vvnumbertext = parseFloat(Math.random(0, 999) * 10000).toFixed(0);
    const newnumber = `${vvnumbertext}`;
    if (numbertype == 3) {
      if (newnumber.length > 3) {
        numbertext = `${newnumber.substring(0, 1)}${newnumber.substring(
          1,
          2
        )}${newnumber.substring(2, 3)}`;
      } else if (newnumber.length < 3) {
        numbertext = `${newnumber}0`;
      } else {
        numbertext = newnumber;
      }
    }
    if (numbertype == 2) {
      if (newnumber.length > 2) {
        numbertext = `${newnumber.substring(0, 1)}${newnumber.substring(1, 2)}`;
      } else if (newnumber.length < 2) {
        numbertext = `${newnumber}0`;
      } else {
        numbertext = newnumber;
      }
    }
    if (numbertype != 0) {
      const memberdispacth = memberActions.handlenumberfix(numbertext);
      dispatch(memberdispacth);
    }
  };

  const handleclearlast = () => {
    const memberdispacth = memberActions.handleclearlast();
    dispatch(memberdispacth);
  };

  const showcheckbox = (title, type) => {
    return (
      <div className="showcheckbox">
        <div className="showcheckbox_title">{title}</div>
        <ul className="press_showcheckbox">
          {[...Array(10).keys()].map((x) => (
            <li key={`zx${x}`} onClick={() => handlecheckbox(type, x)}>
              {x}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const checkclassbtnrl = (numbertext) => {
    const checkdata = betlistrt.find((x) => x.number.includes(numbertext));
    if (checkdata) {
      return "btnnumber_active";
    } else {
      return "btnnumber";
    }
  };

  return (
    <>
      <div className="lottocontaner_left">
        <div className="lotto_list">
          <FormatListNumberedIcon className="mverti" /> ดึงโพย
        </div>
        <div className="btndeposit" onClick={() => handlebtnclickrate()}>
          <BorderColorIcon className="mverti" />
          ใส่ราคา
        </div>
        <div style={{ height: 5 }}></div>
        <div className="lotto_betlist">
          <Badge
            badgeContent={lottototal}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            color="secondary"
          >
            <span>รายการแทง</span>
          </Badge>
        </div>
        {betlist3t && betlist3t.length > 0 && (
          <div className="lotto_type">{betlist3t[0].name}</div>
        )}
        {betlist3t &&
          betlist3t.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`3t${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}
        {betlist3tod && betlist3tod.length > 0 && (
          <div className="lotto_type">{betlist3tod[0].name}</div>
        )}
        {betlist3tod &&
          betlist3tod.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`3tod${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}
        {betlist3l && betlist3l.length > 0 && (
          <div className="lotto_type">{betlist3l[0].name}</div>
        )}
        {betlist3l &&
          betlist3l.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`3l${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}
        {betlist3f && betlist3f.length > 0 && (
          <div className="lotto_type">{betlist3f[0].name}</div>
        )}
        {betlist3f &&
          betlist3f.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`3f${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}
        {betlist2t && betlist2t.length > 0 && (
          <div className="lotto_type">{betlist2t[0].name}</div>
        )}
        {betlist2t &&
          betlist2t.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`2t${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}
        {betlist2l && betlist2l.length > 0 && (
          <div className="lotto_type">{betlist2l[0].name}</div>
        )}
        {betlist2l &&
          betlist2l.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`2l${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}

        {betlistrt && betlistrt.length > 0 && (
          <div className="lotto_type">{betlistrt[0].name}</div>
        )}
        {betlistrt &&
          betlistrt.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`rt${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}
        {betlistrl && betlistrl.length > 0 && (
          <div className="lotto_type">{betlistrl[0].name}</div>
        )}

        {betlistrl &&
          betlistrl.map((x, index) => {
            const classrow =
              index % 2 == 0 ? "lotto_tang_list1" : "lotto_tang_list2";
            return (
              <div className={classrow} key={`rl${index}`}>
                {++index}. <span>{x.number}</span>
              </div>
            );
          })}
      </div>
      <div className="lottocontaner_right">
        <div className="lotto_tap">
          <span
            className={tab1 == true ? "lotto_tap1 active" : "lotto_tap1"}
            onClick={() => goTablotto(1)}
          >
            กดเลขเอง
          </span>
          <span
            className={tab2 == true ? "lotto_tap2 active" : "lotto_tap2"}
            onClick={() => goTablotto(2)}
          >
            เลือกจากแผง
          </span>
        </div>
        <div className="detail_tap1">
          <ul className="lotto_press">
            {lottotypeitems &&
              lottotypeitems.map((x, index) => (
                <li key={index}>
                  <div
                    className={`lotto_btn_${x.numbertype}${checkclass(x)}`}
                    onClick={() => changeitemrate(x)}
                  >
                    <span className="lotto_rate3top">{x.name}</span>
                    <span className="lotto_rate3top2">
                      {x.backnumber == 1 ? (
                        <SyncAltIcon style={{ fontSize: 18, paddingTop: 1 }} />
                      ) : (
                        x.rate
                      )}
                    </span>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        {tab1 == true && (
          <div className="detail_tap2">
            <div>รายการที่เลือก</div>
            <div className="showlistselect">
              {showselectlist()}

              {showboxnumber()}
              {showbuttom()}
              <ul className="lotto_press_submit">
                <li>
                  {" "}
                  <div className="lotto_list">
                    <FormatListNumberedIcon className="mverti" /> ดึงโพย
                  </div>
                </li>
                <li>
                  {" "}
                  <div
                    className="btndeposit"
                    onClick={() => handlebtnclickrate()}
                  >
                    <BorderColorIcon className="mverti" />
                    ใส่ราคา
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}

        {tab2 == true && (
          <div className="detail_tap2">
            <div className="showlistselect">
              {numbertype == 3 && (
                <>
                  <ul className="lotto_press_number_head">
                    <li>
                      <div
                        className={
                          BtnHead3 == "0" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(0)}
                      >
                        000
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "1" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(1)}
                      >
                        100
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "2" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(2)}
                      >
                        200
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "3" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(3)}
                      >
                        300
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "4" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(4)}
                      >
                        400
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "5" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(5)}
                      >
                        500
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "6" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(6)}
                      >
                        600
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "7" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(7)}
                      >
                        700
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "8" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(8)}
                      >
                        800
                      </div>
                    </li>
                    <li>
                      <div
                        className={
                          BtnHead3 == "9" ? "btnnumber_active" : "btnnumber"
                        }
                        onClick={() => handleBtnHead3(9)}
                      >
                        900
                      </div>
                    </li>
                  </ul>
                  <div className="btn3line"></div>
                </>
              )}

              {numbertype == 2 && (
                <>
                  <ul className="press_showcheckboxmain">
                    <li>{showcheckbox("รูดหน้า", 1)}</li>
                    <li>{showcheckbox("รูดหลัง", 2)}</li>
                    <li>{showcheckbox("19 ประตู", 3)}</li>
                  </ul>
                  <div className="btn3line"></div>
                </>
              )}

              {numbertype == 0 && (
                <>
                  <ul className="lotto_press_number_head">
                    <li>
                      <div
                        className={checkclassbtnrl("0")}
                        onClick={() => handleBtnHeadRl3(0)}
                      >
                        0
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("1")}
                        onClick={() => handleBtnHeadRl3(1)}
                      >
                        1
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("2")}
                        onClick={() => handleBtnHeadRl3(2)}
                      >
                        2
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("3")}
                        onClick={() => handleBtnHeadRl3(3)}
                      >
                        3
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("4")}
                        onClick={() => handleBtnHeadRl3(4)}
                      >
                        4
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("5")}
                        onClick={() => handleBtnHeadRl3(5)}
                      >
                        5
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("6")}
                        onClick={() => handleBtnHeadRl3(6)}
                      >
                        6
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("7")}
                        onClick={() => handleBtnHeadRl3(7)}
                      >
                        7
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("8")}
                        onClick={() => handleBtnHeadRl3(8)}
                      >
                        8
                      </div>
                    </li>
                    <li>
                      <div
                        className={checkclassbtnrl("9")}
                        onClick={() => handleBtnHeadRl3(9)}
                      >
                        9
                      </div>
                    </li>
                  </ul>
                  <div className="btn3line"></div>
                </>
              )}

              {numbertype != 0 && (
                <ul className="lotto_press_number_3line">
                  {[...Array(100).keys()].map((x, index) => (
                    <li key={index}>
                      {shownumberlong(BtnHead3, numbertype, x)}
                    </li>
                  ))}
                </ul>
              )}

              <ul className="lotto_press_submit">
                <li>
                  {" "}
                  <div className="lotto_list">
                    <FormatListNumberedIcon className="mverti" /> ดึงโพย
                  </div>
                </li>
                <li>
                  {" "}
                  <div
                    className="btndeposit"
                    onClick={() => handlebtnclickrate()}
                  >
                    <BorderColorIcon className="mverti" />
                    ใส่ราคา
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="totle_lotto_close">
          <div className="lotto_head_close">
            <ErrorIcon className="mverti" />
            เลขปิดรับ{" "}
            <span className="numclose" style={{ fontSize: 14 }}>
              สีแดง
            </span>{" "}
            / <font color="#000">เลขอั้นจ่าย </font>
            <span className="numclose2" style={{ fontSize: 14 }}>
              สีเหลือง
            </span>{" "}
          </div>
          {lottotypeitems &&
            lottotypeitems
              .filter((x) => x.numberlimit && x.numberlimit.length > 0)
              .map((x, index) => (
                <div key={index}>
                  <div className="lotto_btn_close">{x.name}</div>
                  <div className="lotto_btn_close_detail">
                    {x.numberlimit &&
                      x.numberlimit.map((item, index2) => {
                        let classbtn = "numclose2";
                        if (item.remain == 0) {
                          classbtn = "numclose";
                        }
                        return (
                          <span key={`xx${index2}`} className={classbtn}>
                            {item.number}
                          </span>
                        );
                      })}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default CountdownTimer;
