import {
  MEMBER_CHECKWITHDRAW_REQUEST,
  MEMBER_CHECKWITHDRAW_SUCCESS,
  MEMBER_CHECKWITHDRAW_FAILED,
  MEMBER_DEPOSIT_SUCCESS_OK,
  MEMBER_REQUEST,
  MEMBER_SUCCESS,
  MEMBER_GETCREDIT_REQUEST,
  MEMBER_GETCREDIT_SUCCESS,
  MEMBER_GETDATA_REQUEST,
  MEMBER_GETDATA_SUCCESS,
  MEMBER_WITHDRAW_SUCCESS,
  MEMBER_WITHDRAW_REQUEST,
  MEMBER_WITHDRAW_FAILED,
  MEMBER_GETWITHDRAW_REQUEST,
  MEMBER_GETWITHDRAW_SUCCESS,
  MEMBER_SAVE_REQUEST,
  MEMBER_SAVE_SUCCESS,
  MEMBER_SAVE_FAILED,
  MEMBER_DEPOSIT_REQUEST,
  MEMBER_DEPOSIT_FAILED_ALL,
  MEMBER_DEPOSIT_SUCCESS,
  MEMBER_CANCELBOOKING_SUCCESS,
  MEMBER_DEPOSIT_FAILED,
  MEMBER_CINFIRM_REQUEST,
  MEMBER_CHECKBOOKING_SUCCESS,
  MEMBER_CHECKBOOKING_REQUEST,
  MEMBER_CINFIRM_SUCCESS,
  MEMBER_CHECKBOOKING_SUCCESS_FAILED,
  MEMBER_GETHISTORY_FAILED,
  MEMBER_GETHISTORY_SUCCESS,
  MEMBER_GETHISTORY_REQUEST,
  MEMBER_GETBETHISTORY_FAILED,
  MEMBER_GETBETHISTORY_SUCCESS,
  MEMBER_GETBETHISTORY_REQUEST,
  MEMBER_GETMEMBERAFF_SUCCESS,
  MEMBER_GETMEMBERAFF_REQUEST,
  MEMBER_GETMEMBERAFF_FAILED,
  MEMBER_DELBOOKING_SUCCESS,
  MEMBER_GETACC_REQUEST,
  MEMBER_GETACC_SUCCESS,
  MEMBER_GETACC_FAILED,
  MEMBER_GAMELIST_SUCCESS,
  MEMBER_GAMELIST_REQUEST,
  MEMBER_GAMELIST_FAILED,
  MEMBER_SAVEACC_REQUEST,
  MEMBER_SAVEACC_SUCCESS,
  MEMBER_SAVEACC_FAILED,
  MEMBER_OTPACC_REQUEST,
  MEMBER_OTPACC_SUCCESS,
  MEMBER_OTPACC_FAILED,
  MEMBER_GAMELOGIN_REQUEST,
  MEMBER_GAMELOGIN_SUCCESS,
  MEMBER_WINLOSSAFF_REQUEST,
  MEMBER_WINLOSSAFF_SUCCESS,
  MEMBER_WINLOSSAFF_FAILED,
  MEMBER_WITHDRAWMONEY_SUCCESS,
  MEMBER_WITHDRAWMONEY_REQUEST,
  MEMBER_WITHDRAWMONEY_FAILED,
  MEMBER_CHANGEPASS_SUCCESS,
  MEMBER_CHANGEPASS_FAILED,
  MEMBER_GAMEALL_REQUEST,
  MEMBER_GAMEALL_SUCCESS,
  MEMBER_GAMEALL_FAILED,
} from "./action";
import moment from "moment";
import { tod3 } from "./tod3";
import { tod2 } from "./tod2";

import {
  getnumber,
  getnumbertod3,
  getnumbet2t,
  getnumbetrt,
  flipInt,
  getnumberlast,
  getnumbet2tlast,
} from "./function_lotto";

const initailState = {
  errorchangepass: false,
  changepass: false,
  isLoading: false,
  isLoadingHis: false,
  items: [],
  item: {},
  formx: {
    Id: "New",
    Name: null,
    UserName: null,
    Password: null,
    FKBank: null,
    AccName: null,
    AccNo: null,
    Amount: 0,
    Using: true,
  },
  data: null,
  notify: false,
  wait: false,
  notifysuccess: false,
  depositcomplete: false,
  datanotify: {},
  withdrawItems: [],
  showbox: false,
  showrun: false,
  historyItems: [],
  winlossItems: [],
  meta: {},
  winlossmeta: {},
  accountlist: [],
  accountlistotp: [],
  accountload: false,
  dataotp: {},
  statusacc: {},
  accountlistotpconfirm: {},
  gamelist: [],
  link: null,
  loadgame: false,
  winloss: 0,
  memberwinloss: 0,
  total: 0,
  bigpay: false,
  isLoadinggame: false,
  itemsgame: [],
  notifyacc: false,
  count: 0,
  numbertype: 3,
  number1: "|",
  number2: "_",
  number3: "_",
  lottototal: 0,
  betamount: 0,
  addlottototal: 0,
  btnclickrate: false,
  BtnHead3: 0,
  tabcontent: {
    tabmain: true,
    tab1: true,
    tab2: false,
  },
  lottonotify: false,

  lottobetlist: [],
  betlist3t: [], // 3 ตัวบน
  betlist3tod: [], // 3 โตด
  betlist3f: [], // 3 ตัวหน้า
  betlist3l: [], // 3 ตัวล่าง
  betlist2t: [], // 2 ตัวบน
  betlist2l: [], // 2 ตัว่าง
  betlistrt: [], // 2 ตัว่าง
  betlistrl: [], // 2 ตัว่าง

  last_betlist3t: [], // 3 ตัวบนล่าสุด
  last_betlist3tod: [], // 3 โตดล่าสุด
  last_betlist3f: [], // 3 ตัวหน้าล่าสุด
  last_betlist3l: [], // 3 ตัวล่างล่าสุด
  last_betlist2t: [], // 2 ตัวบนล่าสุด
  last_betlist2l: [], // 2 ตัว่างล่าสุด
  last_betlistrt: [], // 2 ตัว่างล่าสุด
  last_betlistrl: [], // 2 ตัว่างล่าสุด

  // รายการหวยที่เปิดแทงแต่ล่ะประเภท
  lottotypeitems: [
    {
      id: 1,
      name: "3ตัวบน",
      rate: 900,
      numbertype: 3,
      typerun: 31,
      backnumber: 0,
      active: true,
      tode: 0,
      remain: 5000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 500, // แทงสูงสุด
      minbet: 1, // แทงขั้นต่ำ
      numberlimit: [
        { number: "098", rate: 50, remain: 0 },
        { number: "901", rate: 50, remain: 0 },
        { number: "916", rate: 50, remain: 500 },
        { number: "089", rate: 50, remain: 500 },
        { number: "059", rate: 50, remain: 500 },
      ],
    },
    {
      id: 2,
      name: "3ตัวโต๊ด",
      rate: 150,
      numbertype: 3,
      typerun: 32,
      backnumber: 0,
      active: false,
      tode: 1,
      remain: 5000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 1000, // แทงสูงสุด
      minbet: 50, // แทงขั้นต่ำ
      numberlimit: [
        { number: "098", rate: 50, remain: 0 },
        { number: "935", rate: 50, remain: 0 },
        { number: "079", rate: 50, remain: 500 },
        { number: "059", rate: 50, remain: 500 },
      ],
    },
    {
      id: 3,
      name: "3ตัวหน้า",
      rate: 450,
      numbertype: 3,
      typerun: 33,
      backnumber: 0,
      active: false,
      tode: 0,
      remain: 5000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 1000, // แทงสูงสุด
      minbet: 1, // แทงขั้นต่ำ
      numberlimit: [
        { number: "098", rate: 50, remain: 0 },
        { number: "079", rate: 50, remain: 500 },
        { number: "059", rate: 50, remain: 500 },
      ],
    },
    {
      id: 4,
      name: "3ตัวล่าง",
      rate: 450,
      numbertype: 3,
      typerun: 34,
      backnumber: 0,
      active: false,
      tode: 0,
      remain: 5000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 1000, // แทงสูงสุด
      minbet: 1, // แทงขั้นต่ำ
      numberlimit: [
        { number: "098", rate: 50, remain: 0 },
        { number: "079", rate: 50, remain: 500 },
        { number: "059", rate: 50, remain: 500 },
      ],
    },
    {
      id: 5,
      name: "2ตัวบน",
      rate: 90,
      numbertype: 2,
      backnumber: 0,
      active: false,
      tode: 0,
      typerun: 3,
      remain: 5000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 2000, // แทงสูงสุด
      minbet: 1, // แทงขั้นต่ำ
      numberlimit: [
        { number: "98", rate: 50, remain: 0 },
        { number: "79", rate: 50, remain: 500 },
        { number: "95", rate: 50, remain: 500 },
        { number: "59", rate: 50, remain: 500 },
        { number: "89", rate: 50, remain: 500 },
      ],
    },
    {
      id: 6,
      name: "2ตัวล่าง",
      rate: 90,
      numbertype: 2,
      backnumber: 0,
      active: false,
      tode: 0,
      typerun: 4,
      remain: 5000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 2000, // แทงสูงสุด
      minbet: 1, // แทงขั้นต่ำ
      numberlimit: [
        { number: "98", rate: 50, remain: 0 },
        { number: "79", rate: 50, remain: 500 },
        { number: "59", rate: 50, remain: 500 },
      ],
    },
    {
      id: 7,
      name: "3ตัวกลับ",
      rate: 450,
      numbertype: 3,
      backnumber: 1,
      active: false,
      tode: 0,
    },
    {
      id: 8,
      name: "2ตัวกลับ",
      rate: 450,
      numbertype: 2,
      backnumber: 1,
      active: false,
      tode: 0,
    },
    {
      id: 9,
      name: "วิ่งบน",
      rate: 3.2,
      numbertype: 0,
      backnumber: 0,
      active: false,
      tode: 0,
      typerun: 1, // วิ่งบน
      remain: 500000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 20000, // แทงสูงสุด
      minbet: 10, // แทงขั้นต่ำ
    },
    {
      id: 10,
      name: "วิ่งล่าง",
      rate: 4.2,
      numbertype: 0,
      backnumber: 0,
      active: false,
      tode: 0,
      typerun: 2, // วิ่งล่าง
      remain: 500000, // ยอดคงเหลือทั้งหมดที่รับแทง
      maxbet: 20000, // แทงสูงสุด
      minbet: 10, // แทงขั้นต่ำ
    },
  ],
};

export default function (state = initailState, action) {
  switch (action.type) {
    case "handleremoveall": {
      return {
        ...state,
        number1: "|",
        number2: "_",
        number3: "_",
        lottototal: 0,
        betamount: 0,
        addlottototal: 0,
        lottonotify: false,
        lottobetlist: [],
        betlist3t: [], // 3 ตัวบน
        betlist3tod: [], // 3 โตด
        betlist3f: [], // 3 ตัวหน้า
        betlist3l: [], // 3 ตัวล่าง
        betlist2t: [], // 2 ตัวบน
        betlist2l: [], // 2 ตัว่าง
        betlistrt: [], // 2 ตัว่าง
        betlistrl: [], // 2 ตัว่าง

        last_betlist3t: [], // 3 ตัวบนล่าสุด
        last_betlist3tod: [], // 3 โตดล่าสุด
        last_betlist3f: [], // 3 ตัวหน้าล่าสุด
        last_betlist3l: [], // 3 ตัวล่างล่าสุด
        last_betlist2t: [], // 2 ตัวบนล่าสุด
        last_betlist2l: [], // 2 ตัว่างล่าสุด
        last_betlistrt: [], // 2 ตัว่างล่าสุด
        last_betlistrl: [], // 2 ตัว่างล่าสุด
        btnclickrate: false,
        tabcontent: {
          tabmain: true,
          tab1: true,
          tab2: false,
        },
      };
    }

    case "handleTablotto": {
      const tabnumber = action.payload;
      return {
        ...state,
        tabcontent: {
          tabmain: true,
          tab1: tabnumber == 1 ? true : false,
          tab2: tabnumber == 2 ? true : false,
        },
      };
    }

    case "handleBtnHead3": {
      const tabnumber = action.payload;
      return {
        ...state,
        BtnHead3: tabnumber,
      };
    }

    case "hidelottonotify": {
      return {
        ...state,
        lottonotify: false,
      };
    }
    case "btnclickrate": {
      if (state.btnclickrate == true) {
        // แสดงว่าต้องการปิดแทปใส่ราคา
        return {
          ...state,
          btnclickrate: false,
          tabcontent: {
            tabmain: true,
            tab1: state.tabcontent.tab1,
            tab2: state.tabcontent.tab2,
          },
        };
      } else {
        // แสดงว่าต้องการเปิดแทปใส่ราคา
        return {
          ...state,
          btnclickrate: true,
          tabcontent: {
            tabmain: false,
            tab1: state.tabcontent.tab1,
            tab2: state.tabcontent.tab2,
          },
        };
      }
    }

    case "freestate": {
      return {
        ...state,
      };
    }

    case "handletabaction": {
      const items = action.payload.items;
      const { tabnumber } = items;
      if (tabnumber == 1) {
      }
      return {
        ...state,
      };
    }

    case "btnclearnumber": {
      let count = state.count;
      let number1 = state.number1;
      let number2 = state.number2;
      let number3 = state.number3;
      if (state.count == 1) {
        --count;
        number1 = "|";
        number2 = "_";
        number3 = "_";
      }
      if (state.count == 2) {
        --count;
        number1 = state.number1;
        number2 = "|";
        number3 = "_";
      }
      return {
        ...state,
        number1,
        number2,
        number3,
        count,
      };
    }

    case "handleamount": {
      const items = action.payload.items;

      const { delitem, numbertype } = items;

      const betlist3t = state[numbertype];

      if (delitem == 2) {
        // ประเภทใส่ราคาทีเดียว
        // หา state ที่เลือกรายการ

        function genbetlistall(betamount, betlist3t) {
          const vvv = betlist3t.map((x) => {
            const payout = betamount * x.rate;
            let active = x.active;
            if (betamount > x.maxbet) {
              active = 0;
            } else {
              active = 1;
            }
            return {
              ...x,
              active,
              payout,
              amount: betamount,
            };
          });
          return vvv;
        }
        const { betamount } = items;

        const newitems_betlistrt = genbetlistall(betamount, state.betlistrt);
        const newitems_betlistrl = genbetlistall(betamount, state.betlistrl);
        const newitems_betlist2t = genbetlistall(betamount, state.betlist2t);
        const newitems_betlist2l = genbetlistall(betamount, state.betlist2l);
        const newitems_betlist3t = genbetlistall(betamount, state.betlist3t);
        const newitems_betlist3l = genbetlistall(betamount, state.betlist3l);
        const newitems_betlist3f = genbetlistall(betamount, state.betlist3f);
        const newitems_betlist3tod = genbetlistall(
          betamount,
          state.betlist3tod
        );

        const sumbetamount_betlist3t = newitems_betlist3t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        const sumbetamount_betlist3l = newitems_betlist3l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const sumbetamount_betlist3tod = newitems_betlist3tod.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const sumbetamount_betlist3f = newitems_betlist3f.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const sumbetamount_betlist2t = newitems_betlist2t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const sumbetamount_betlist2l = newitems_betlist2l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        const sumbetamount_betlistrt = newitems_betlistrt.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        const sumbetamount_betlistrl = newitems_betlistrl.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        const sumbetamount =
          sumbetamount_betlistrt +
          sumbetamount_betlistrl +
          sumbetamount_betlist2t +
          sumbetamount_betlist2l +
          sumbetamount_betlist3t +
          sumbetamount_betlist3l +
          sumbetamount_betlist3f +
          sumbetamount_betlist3tod;
        return {
          ...state,
          betlist3t: newitems_betlist3t,
          betlist3l: newitems_betlist3l,
          betlist3tod: newitems_betlist3tod,
          betlist3f: newitems_betlist3f,
          betlist2t: newitems_betlist2t,
          betlist2l: newitems_betlist2l,
          betlistrt: newitems_betlistrt,
          betlistrl: newitems_betlistrl,
          betamount: sumbetamount,
        };
      } else if (delitem == 1) {
        // ประเภทลบ
        const { no } = items;
        const newitems = betlist3t.filter((x) => x.no != no);
        const sumbetamount = newitems.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        let sumbetamount_betlistrt = state.betlistrt.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        let sumbetamount_betlistrl = state.betlistrl.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        let sumbetamount_betlist3t = state.betlist3t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        let sumbetamount_betlist3l = state.betlist3l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        let sumbetamount_betlist3tod = state.betlist3tod.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        let sumbetamount_betlist3f = state.betlist3f.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        let sumbetamount_betlist2t = state.betlist2t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        let sumbetamount_betlist2l = state.betlist2l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        let total_betlistrt = state.betlistrt.length;
        let total_betlistrl = state.betlistrl.length;
        let total_betlist2t = state.betlist2t.length;
        let total_betlist2l = state.betlist2l.length;
        let total_betlist3t = state.betlist3t.length;
        let total_betlist3l = state.betlist3l.length;
        let total_betlist3f = state.betlist3f.length;
        let total_betlist3tod = state.betlist3tod.length;

        if (numbertype == "betlist3t") {
          sumbetamount_betlist3t = sumbetamount;
          total_betlist3t = newitems.length;
        }
        if (numbertype == "betlist3l") {
          sumbetamount_betlist3l = sumbetamount;
          total_betlist3l = newitems.length;
        }
        if (numbertype == "betlist3f") {
          sumbetamount_betlist3f = sumbetamount;
          total_betlist3f = newitems.length;
        }
        if (numbertype == "betlist3tod") {
          sumbetamount_betlist3tod = sumbetamount;
          total_betlist3tod = newitems.length;
        }
        if (numbertype == "betlist2t") {
          sumbetamount_betlist2t = sumbetamount;
          total_betlist2t = newitems.length;
        }
        if (numbertype == "betlist2l") {
          sumbetamount_betlist2l = sumbetamount;
          total_betlist2l = newitems.length;
        }

        if (numbertype == "betlistrt") {
          sumbetamount_betlistrt = sumbetamount;
          total_betlistrt = newitems.length;
        }
        if (numbertype == "betlistrl") {
          sumbetamount_betlistrl = sumbetamount;
          total_betlistrl = newitems.length;
        }

        const sumbetamount2 =
          sumbetamount_betlistrt +
          sumbetamount_betlistrl +
          sumbetamount_betlist2t +
          sumbetamount_betlist2l +
          sumbetamount_betlist3t +
          sumbetamount_betlist3l +
          sumbetamount_betlist3f +
          sumbetamount_betlist3tod;

        const sumtotallist =
          total_betlistrl +
          total_betlistrl +
          total_betlist2t +
          total_betlist2l +
          total_betlist3t +
          total_betlist3l +
          total_betlist3f +
          total_betlist3tod;

        return {
          ...state,
          [numbertype]: newitems,
          betamount: sumbetamount2,
          lottototal: sumtotallist,
        };
      } else {
        // ประเภทแก้ไขทีล่ะรายการ
        const { no, betamount, rate, maxbet } = items;
        const index = betlist3t.findIndex((x) => x.no == no);
        if (index !== -1) {
          const data = betlist3t[index];
          const payout = betamount * rate;
          let active = items.active;
          if (betamount > maxbet) {
            active = 0;
          } else {
            active = 1;
          }
          // แสดงว่ามีค่า
          const newitems = [
            ...betlist3t.slice(0, index),

            { ...data, amount: betamount, payout: payout, active },

            ...betlist3t.slice(index + 1),
          ];

          const sumbetamount = newitems.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );

          let sumbetamount_betlistrt = state.betlistrt.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );

          let sumbetamount_betlistrl = state.betlistrl.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );

          let sumbetamount_betlist3t = state.betlist3t.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );

          let sumbetamount_betlist3l = state.betlist3l.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );
          let sumbetamount_betlist3tod = state.betlist3tod.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );
          let sumbetamount_betlist3f = state.betlist3f.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );
          let sumbetamount_betlist2t = state.betlist2t.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );
          let sumbetamount_betlist2l = state.betlist2l.reduce(
            (total, list) => parseInt(total) + parseInt(list.amount),
            0
          );

          if (numbertype == "betlist3t") {
            sumbetamount_betlist3t = sumbetamount;
          }
          if (numbertype == "betlist3l") {
            sumbetamount_betlist3l = sumbetamount;
          }
          if (numbertype == "betlist3f") {
            sumbetamount_betlist3f = sumbetamount;
          }
          if (numbertype == "betlist3tod") {
            sumbetamount_betlist3tod = sumbetamount;
          }
          if (numbertype == "betlist2t") {
            sumbetamount_betlist2t = sumbetamount;
          }
          if (numbertype == "betlist2l") {
            sumbetamount_betlist2l = sumbetamount;
          }

          if (numbertype == "betlistrt") {
            sumbetamount_betlistrt = sumbetamount;
          }
          if (numbertype == "betlistrl") {
            sumbetamount_betlistrl = sumbetamount;
          }

          const sumbetamount2 =
            sumbetamount_betlistrt +
            sumbetamount_betlistrl +
            sumbetamount_betlist2t +
            sumbetamount_betlist2l +
            sumbetamount_betlist3t +
            sumbetamount_betlist3l +
            sumbetamount_betlist3f +
            sumbetamount_betlist3tod;

          return {
            ...state,
            [numbertype]: newitems,
            betamount: sumbetamount2,
          };
        } else {
          return {
            ...state,
            lottonotify: false,
          };
        }
      }
    }

    case "handleclearlast": {
      const {
        betlistrt,
        betlistrl,
        betlist2t,
        betlist2l,
        betlist3t,
        betlist3tod,
        betlist3l,
        betlist3f,
        last_betlistrt,
        last_betlistrl,
        last_betlist2t,
        last_betlist2l,
        last_betlist3t,
        last_betlist3tod,
        last_betlist3l,
        last_betlist3f,
      } = state;
      const new_betlist2t = betlist2t.reduce((result, list) => {
        const checkdata = last_betlist2t.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);
      const new_betlist2l = betlist2l.reduce((result, list) => {
        const checkdata = last_betlist2l.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);
      const new_betlist3l = betlist3l.reduce((result, list) => {
        const checkdata = last_betlist3l.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);
      const new_betlist3t = betlist3t.reduce((result, list) => {
        const checkdata = last_betlist3t.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);
      const new_betlist3f = betlist3f.reduce((result, list) => {
        const checkdata = last_betlist3f.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);
      const new_betlist3tod = betlist3tod.reduce((result, list) => {
        const checkdata = last_betlist3tod.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);
      const new_betlistrt = betlistrt.reduce((result, list) => {
        const checkdata = last_betlistrt.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);
      const new_betlistrl = betlistrl.reduce((result, list) => {
        const checkdata = last_betlistrl.find((x) => x.number == list.number);
        if (checkdata) {
          return [...result];
        } else {
          return [...result, list];
        }
      }, []);

      const newlottototal =
        parseInt(new_betlistrt.length) +
        parseInt(new_betlistrl.length) +
        parseInt(new_betlist2t.length) +
        parseInt(new_betlist3t.length) +
        parseInt(new_betlist3tod.length) +
        parseInt(new_betlist3l.length) +
        parseInt(new_betlist3f.length) +
        parseInt(new_betlist2l.length);

      const betamount_newbetlistrt = new_betlistrt.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlistrl = new_betlistrl.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2t = new_betlist2t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2l = new_betlist2l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3t = new_betlist3t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3l = new_betlist3l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3tod = new_betlist3tod.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3f = new_betlist3f.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );

      const newbetamount =
        parseInt(betamount_newbetlistrt ? betamount_newbetlistrt : 0) +
        parseInt(betamount_newbetlistrl ? betamount_newbetlistrl : 0) +
        parseInt(betamount_newbetlist2t ? betamount_newbetlist2t : 0) +
        parseInt(betamount_newbetlist2l ? betamount_newbetlist2l : 0) +
        parseInt(betamount_newbetlist3t ? betamount_newbetlist3t : 0) +
        parseInt(betamount_newbetlist3l ? betamount_newbetlist3l : 0) +
        parseInt(betamount_newbetlist3tod ? betamount_newbetlist3tod : 0) +
        parseInt(betamount_newbetlist3f ? betamount_newbetlist3f : 0);

      return {
        ...state,
        betamount: newbetamount,
        lottototal: newlottototal,
        betlist2t: new_betlist2t,
        betlist2l: new_betlist2l,
        betlist3l: new_betlist3l,
        betlist3t: new_betlist3t,
        betlist3f: new_betlist3f,
        betlist3tod: new_betlist3tod,
        betlistrt: new_betlistrt,
        betlistrl: new_betlistrl,
      };
    }

    case "handlecheckbox": {
      const {
        lottotypeitems,
        betlistrt,
        betlistrl,
        betlist2t,
        betlist2l,
        betlist3t,
        betlist3tod,
        betlist3l,
        betlist3f,
      } = state;

      let newbetlistrt = betlistrt;
      let newbetlistrl = betlistrl;
      let newbetlist2t = betlist2t;
      let newbetlist2l = betlist2l;
      let newbetlist3t = betlist3t;
      let newbetlist3tod = betlist3tod;
      let newbetlist3l = betlist3l;
      let newbetlist3f = betlist3f;

      let lastbetlist2t = [];
      let lastbetlist2l = [];

      const { type, numbertext } = action.payload;

      // เช็คว่ามีกลับด้วยมั้ย
      const backnumber = lottotypeitems.find(
        (x) => x.active == true && x.backnumber == 1
      );

      if (type == 1 || type == 2) {
        const checknewbetlist2t = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 3
        );
        if (checknewbetlist2t) {
          let rate = checknewbetlist2t.rate;
          let remain = checknewbetlist2t.remain;
          let minbet = checknewbetlist2t.minbet;

          lastbetlist2t = [...Array(10).keys()].reduce((result, x) => {
            let newnumber = `${x}${numbertext}`;
            if (type == 2) {
              newnumber = `${x}${numbertext}`;
            }
            if (type == 1) {
              newnumber = `${numbertext}${x}`;
            }

            const { numberlimit } = checknewbetlist2t;
            const checknumberlimit = numberlimit.find((x) =>
              x.number.includes(newnumber)
            );

            if (checknumberlimit) {
              rate = checknumberlimit.rate;
              remain = checknumberlimit.remain;
            } else {
              rate = checknewbetlist2t.rate;
              remain = checknewbetlist2t.remain;
            }

            let payout = parseFloat(minbet) * parseFloat(rate);
            if (remain == 0) {
              payout = 0;
              minbet = 0;
            }

            let newobject = {
              name: checknewbetlist2t.name,
              typerun: 3,
              rate: rate,
              minbet: checknewbetlist2t.minbet,
              maxbet: checknewbetlist2t.maxbet,
              payout,
              remain,
              amount: minbet,
              number: newnumber,
            };

            // ถ้ามีกลับด้วย
            let backnumberitems = [];
            if (backnumber) {
              const backumbernew = flipInt(newnumber);

              let rate2 = checknewbetlist2t.rate;
              let remain2 = checknewbetlist2t.remain;
              let minbet2 = checknewbetlist2t.minbet;

              if (backumbernew != newnumber) {
                //const { numberlimit } = checknewbetlist2t;
                const checknumberlimit2 = numberlimit.find((x) =>
                  x.number.includes(backumbernew)
                );

                if (checknumberlimit2) {
                  rate2 = checknumberlimit2.rate;
                  remain2 = checknumberlimit2.remain;
                } else {
                  rate2 = checknewbetlist2t.rate;
                  remain2 = checknewbetlist2t.remain;
                }

                let payout2 =
                  parseFloat(checknewbetlist2t.minbet) * parseFloat(rate2);
                if (remain2 == 0) {
                  payout2 = 0;
                  minbet2 = 0;
                }

                backnumberitems = [
                  {
                    name: checknewbetlist2t.name,
                    typerun: 3,
                    rate: rate2,
                    minbet: checknewbetlist2t.minbet,
                    maxbet: checknewbetlist2t.maxbet,
                    payout: payout2,
                    remain: remain2,
                    amount: minbet2,
                    number: backumbernew,
                  },
                ];
              }
            }

            return [...result, ...backnumberitems, newobject];
          }, []);

          newbetlist2t = [...Array(10).keys()].reduce(
            (result, x) => {
              let newnumber = `${x}${numbertext}`;
              if (type == 2) {
                newnumber = `${x}${numbertext}`;
              }
              if (type == 1) {
                newnumber = `${numbertext}${x}`;
              }

              const { numberlimit } = checknewbetlist2t;
              const checknumberlimit = numberlimit.find((x) =>
                x.number.includes(newnumber)
              );

              if (checknumberlimit) {
                rate = checknumberlimit.rate;
                remain = checknumberlimit.remain;
              } else {
                rate = checknewbetlist2t.rate;
                remain = checknewbetlist2t.remain;
              }

              let payout = parseFloat(minbet) * parseFloat(rate);
              if (remain == 0) {
                payout = 0;
                minbet = 0;
              }

              let newobject = {
                name: checknewbetlist2t.name,
                typerun: 3,
                rate: rate,
                minbet: checknewbetlist2t.minbet,
                maxbet: checknewbetlist2t.maxbet,
                payout,
                remain,
                amount: minbet,
                number: newnumber,
              };

              // ถ้ามีกลับด้วย
              let backnumberitems = [];
              if (backnumber) {
                const backumbernew = flipInt(newnumber);

                let rate2 = checknewbetlist2t.rate;
                let remain2 = checknewbetlist2t.remain;
                let minbet2 = checknewbetlist2t.minbet;

                if (backumbernew != newnumber) {
                  //const { numberlimit } = checknewbetlist2t;
                  const checknumberlimit2 = numberlimit.find((x) =>
                    x.number.includes(backumbernew)
                  );

                  if (checknumberlimit2) {
                    rate2 = checknumberlimit2.rate;
                    remain2 = checknumberlimit2.remain;
                  } else {
                    rate2 = checknewbetlist2t.rate;
                    remain2 = checknewbetlist2t.remain;
                  }

                  let payout2 =
                    parseFloat(checknewbetlist2t.minbet) * parseFloat(rate2);
                  if (remain2 == 0) {
                    payout2 = 0;
                    minbet2 = 0;
                  }

                  backnumberitems = [
                    {
                      name: checknewbetlist2t.name,
                      typerun: 3,
                      rate: rate2,
                      minbet: checknewbetlist2t.minbet,
                      maxbet: checknewbetlist2t.maxbet,
                      payout: payout2,
                      remain: remain2,
                      amount: minbet2,
                      number: backumbernew,
                    },
                  ];
                }
              }

              return [...result, ...backnumberitems, newobject];
            },
            [...betlist2t]
          );
        }

        // 2 ตัวล่าง
        const checknewbetlist2l = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 4
        );
        if (checknewbetlist2l) {
          let rate = checknewbetlist2l.rate;
          let remain = checknewbetlist2l.remain;
          let minbet = checknewbetlist2l.minbet;

          newbetlist2l = [...Array(10).keys()].reduce(
            (result, x) => {
              let newnumber = `${x}${numbertext}`;
              if (type == 2) {
                newnumber = `${x}${numbertext}`;
              }
              if (type == 1) {
                newnumber = `${numbertext}${x}`;
              }

              const { numberlimit } = checknewbetlist2l;
              const checknumberlimit = numberlimit.find((x) =>
                x.number.includes(newnumber)
              );

              if (checknumberlimit) {
                rate = checknumberlimit.rate;
                remain = checknumberlimit.remain;
              } else {
                rate = checknewbetlist2l.rate;
                remain = checknewbetlist2l.remain;
              }

              let payout = parseFloat(minbet) * parseFloat(rate);
              if (remain == 0) {
                payout = 0;
                minbet = 0;
              }

              let newobject = {
                name: checknewbetlist2l.name,
                typerun: 4,
                rate: rate,
                minbet: checknewbetlist2l.minbet,
                maxbet: checknewbetlist2l.maxbet,
                payout,
                remain,
                amount: minbet,
                number: newnumber,
              };

              // ถ้ามีกลับด้วย
              let backnumberitems = [];
              if (backnumber) {
                const backumbernew = flipInt(newnumber);

                let rate2 = checknewbetlist2l.rate;
                let remain2 = checknewbetlist2l.remain;
                let minbet2 = checknewbetlist2l.minbet;

                if (backumbernew != newnumber) {
                  //const { numberlimit } = checknewbetlist2t;
                  const checknumberlimit2 = numberlimit.find((x) =>
                    x.number.includes(backumbernew)
                  );

                  if (checknumberlimit2) {
                    rate2 = checknumberlimit2.rate;
                    remain2 = checknumberlimit2.remain;
                  } else {
                    rate2 = checknewbetlist2l.rate;
                    remain2 = checknewbetlist2l.remain;
                  }

                  let payout2 =
                    parseFloat(checknewbetlist2l.minbet) * parseFloat(rate2);
                  if (remain2 == 0) {
                    payout2 = 0;
                    minbet2 = 0;
                  }

                  backnumberitems = [
                    {
                      name: checknewbetlist2l.name,
                      typerun: 4,
                      rate: rate2,
                      minbet: checknewbetlist2l.minbet,
                      maxbet: checknewbetlist2l.maxbet,
                      payout: payout2,
                      remain: remain2,
                      amount: minbet2,
                      number: backumbernew,
                    },
                  ];
                }
              }

              return [...result, ...backnumberitems, newobject];
            },
            [...betlist2l]
          );

          lastbetlist2l = [...Array(10).keys()].reduce((result, x) => {
            let newnumber = `${x}${numbertext}`;
            if (type == 2) {
              newnumber = `${x}${numbertext}`;
            }
            if (type == 1) {
              newnumber = `${numbertext}${x}`;
            }

            const { numberlimit } = checknewbetlist2l;
            const checknumberlimit = numberlimit.find((x) =>
              x.number.includes(newnumber)
            );

            if (checknumberlimit) {
              rate = checknumberlimit.rate;
              remain = checknumberlimit.remain;
            } else {
              rate = checknewbetlist2l.rate;
              remain = checknewbetlist2l.remain;
            }

            let payout = parseFloat(minbet) * parseFloat(rate);
            if (remain == 0) {
              payout = 0;
              minbet = 0;
            }

            let newobject = {
              name: checknewbetlist2l.name,
              typerun: 4,
              rate: rate,
              minbet: checknewbetlist2l.minbet,
              maxbet: checknewbetlist2l.maxbet,
              payout,
              remain,
              amount: minbet,
              number: newnumber,
            };

            // ถ้ามีกลับด้วย
            let backnumberitems = [];
            if (backnumber) {
              const backumbernew = flipInt(newnumber);

              let rate2 = checknewbetlist2l.rate;
              let remain2 = checknewbetlist2l.remain;
              let minbet2 = checknewbetlist2l.minbet;

              if (backumbernew != newnumber) {
                //const { numberlimit } = checknewbetlist2t;
                const checknumberlimit2 = numberlimit.find((x) =>
                  x.number.includes(backumbernew)
                );

                if (checknumberlimit2) {
                  rate2 = checknumberlimit2.rate;
                  remain2 = checknumberlimit2.remain;
                } else {
                  rate2 = checknewbetlist2l.rate;
                  remain2 = checknewbetlist2l.remain;
                }

                let payout2 =
                  parseFloat(checknewbetlist2l.minbet) * parseFloat(rate2);
                if (remain2 == 0) {
                  payout2 = 0;
                  minbet2 = 0;
                }

                backnumberitems = [
                  {
                    name: checknewbetlist2l.name,
                    typerun: 4,
                    rate: rate2,
                    minbet: checknewbetlist2l.minbet,
                    maxbet: checknewbetlist2l.maxbet,
                    payout: payout2,
                    remain: remain2,
                    amount: minbet2,
                    number: backumbernew,
                  },
                ];
              }
            }

            return [...result, ...backnumberitems, newobject];
          }, []);
        }

        const sumbetamount_betlist3t = betlist3t.length;
        const sumbetamount_betlist3l = betlist3l.length;
        const sumbetamount_betlist3tod = betlist3tod.length;
        const sumbetamount_betlist3f = betlist3f.length;
        const sumbetamount_betlist2t = newbetlist2t.length;
        const sumbetamount_betlist2l = newbetlist2l.length;
        const sumbetamount_betlistrt = betlistrt.length;
        const sumbetamount_betlistrl = betlistrl.length;

        const sumlottototal =
          sumbetamount_betlistrt +
          sumbetamount_betlistrl +
          sumbetamount_betlist2t +
          sumbetamount_betlist2l +
          sumbetamount_betlist3t +
          sumbetamount_betlist3l +
          sumbetamount_betlist3f +
          sumbetamount_betlist3tod;

        const addlottototal =
          parseInt(sumlottototal) - parseInt(state.lottototal);

        const betamount_newbetlistrt = newbetlistrt.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlistrl = newbetlistrl.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist2t = newbetlist2t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist2l = newbetlist2l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3t = newbetlist3t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3l = newbetlist3l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3tod = newbetlist3tod.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3f = newbetlist3f.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        const newbetamount =
          parseInt(betamount_newbetlistrt ? betamount_newbetlistrt : 0) +
          parseInt(betamount_newbetlistrl ? betamount_newbetlistrl : 0) +
          parseInt(betamount_newbetlist2t ? betamount_newbetlist2t : 0) +
          parseInt(betamount_newbetlist2l ? betamount_newbetlist2l : 0) +
          parseInt(betamount_newbetlist3t ? betamount_newbetlist3t : 0) +
          parseInt(betamount_newbetlist3l ? betamount_newbetlist3l : 0) +
          parseInt(betamount_newbetlist3tod ? betamount_newbetlist3tod : 0) +
          parseInt(betamount_newbetlist3f ? betamount_newbetlist3f : 0);

        return {
          ...state,
          betlist2t: newbetlist2t.map((x, index) => ({ ...x, no: ++index })),
          betlist2l: newbetlist2l.map((x, index) => ({ ...x, no: ++index })),
          last_betlist2t: lastbetlist2t.map((x, index) => ({
            ...x,
            no: ++index,
          })),
          last_betlist2l: lastbetlist2l.map((x, index) => ({
            ...x,
            no: ++index,
          })),
          lottototal: sumlottototal,
          lottonotify: true,
          addlottototal,
          betamount: newbetamount,
        };
      } else {
        // 2 ตัวบน
        const checknewbetlist2t = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 3
        );
        if (checknewbetlist2t) {
          let rate = checknewbetlist2t.rate;
          let remain = checknewbetlist2t.remain;
          let minbet = checknewbetlist2t.minbet;
          const newtxtnumber = tod2.filter((x) => x.includes(numbertext));

          newbetlist2t = newtxtnumber.reduce(
            (result, x) => {
              let newnumber = `${x}`;

              const { numberlimit } = checknewbetlist2t;
              const checknumberlimit = numberlimit.find((x) =>
                x.number.includes(newnumber)
              );

              if (checknumberlimit) {
                rate = checknumberlimit.rate;
                remain = checknumberlimit.remain;
              } else {
                rate = checknewbetlist2t.rate;
                remain = checknewbetlist2t.remain;
              }

              let payout = parseFloat(minbet) * parseFloat(rate);
              if (remain == 0) {
                payout = 0;
                minbet = 0;
              }

              let newobject = {
                name: checknewbetlist2t.name,
                typerun: 3,
                rate: rate,
                minbet: checknewbetlist2t.minbet,
                maxbet: checknewbetlist2t.maxbet,
                payout,
                remain,
                amount: minbet,
                number: newnumber,
              };

              return [...result, newobject];
            },
            [...betlist2t]
          );
        }

        // 2 ตัวล่าง
        const checknewbetlist2l = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 4
        );
        if (checknewbetlist2l) {
          let rate = checknewbetlist2l.rate;
          let remain = checknewbetlist2l.remain;
          let minbet = checknewbetlist2l.minbet;
          const newtxtnumber = tod2.filter((x) => x.includes(numbertext));

          newbetlist2l = newtxtnumber.reduce(
            (result, x) => {
              let newnumber = `${x}`;

              const { numberlimit } = checknewbetlist2l;
              const checknumberlimit = numberlimit.find((x) =>
                x.number.includes(newnumber)
              );

              if (checknumberlimit) {
                rate = checknumberlimit.rate;
                remain = checknumberlimit.remain;
              } else {
                rate = checknewbetlist2l.rate;
                remain = checknewbetlist2l.remain;
              }

              let payout = parseFloat(minbet) * parseFloat(rate);
              if (remain == 0) {
                payout = 0;
                minbet = 0;
              }

              let newobject = {
                name: checknewbetlist2l.name,
                typerun: 4,
                rate: rate,
                minbet: checknewbetlist2l.minbet,
                maxbet: checknewbetlist2l.maxbet,
                payout,
                remain,
                amount: minbet,
                number: newnumber,
              };

              return [...result, newobject];
            },
            [...betlist2l]
          );
        }

        const sumbetamount_betlist3t = betlist3t.length;
        const sumbetamount_betlist3l = betlist3l.length;
        const sumbetamount_betlist3tod = betlist3tod.length;
        const sumbetamount_betlist3f = betlist3f.length;
        const sumbetamount_betlist2t = newbetlist2t.length;
        const sumbetamount_betlist2l = newbetlist2l.length;
        const sumbetamount_betlistrt = betlistrt.length;
        const sumbetamount_betlistrl = betlistrl.length;

        const sumlottototal =
          sumbetamount_betlistrt +
          sumbetamount_betlistrl +
          sumbetamount_betlist2t +
          sumbetamount_betlist2l +
          sumbetamount_betlist3t +
          sumbetamount_betlist3l +
          sumbetamount_betlist3f +
          sumbetamount_betlist3tod;

        const addlottototal =
          parseInt(sumlottototal) - parseInt(state.lottototal);

        const betamount_newbetlistrt = newbetlistrt.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlistrl = newbetlistrl.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist2t = newbetlist2t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist2l = newbetlist2l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3t = newbetlist3t.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3l = newbetlist3l.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3tod = newbetlist3tod.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );
        const betamount_newbetlist3f = newbetlist3f.reduce(
          (total, list) => parseInt(total) + parseInt(list.amount),
          0
        );

        const newbetamount =
          parseInt(betamount_newbetlistrt ? betamount_newbetlistrt : 0) +
          parseInt(betamount_newbetlistrl ? betamount_newbetlistrl : 0) +
          parseInt(betamount_newbetlist2t ? betamount_newbetlist2t : 0) +
          parseInt(betamount_newbetlist2l ? betamount_newbetlist2l : 0) +
          parseInt(betamount_newbetlist3t ? betamount_newbetlist3t : 0) +
          parseInt(betamount_newbetlist3l ? betamount_newbetlist3l : 0) +
          parseInt(betamount_newbetlist3tod ? betamount_newbetlist3tod : 0) +
          parseInt(betamount_newbetlist3f ? betamount_newbetlist3f : 0);

        return {
          ...state,
          betlist2l: newbetlist2l.map((x, index) => ({ ...x, no: ++index })),
          betlist2t: newbetlist2t.map((x, index) => ({ ...x, no: ++index })),
          lottototal: sumlottototal,
          lottonotify: true,
          addlottototal,
          betamount: newbetamount,
        };
      }
    }

    case "handlenumberfix": {
      const {
        lottotypeitems,
        betlistrt,
        betlistrl,
        betlist2t,
        betlist2l,
        numbertype,
        betlist3t,
        betlist3tod,
        betlist3l,
        betlist3f,
      } = state;
      let newlottonotify = false;

      let newbetlistrt = betlistrt;
      let newbetlistrl = betlistrl;
      let newbetlist2t = betlist2t;
      let newbetlist2l = betlist2l;
      let newbetlist3t = betlist3t;
      let newbetlist3tod = betlist3tod;
      let newbetlist3l = betlist3l;
      let newbetlist3f = betlist3f;
      let newlast_betlistrt = [];

      let lastbetlistrt = [];
      let lastbetlistrl = [];
      let lastbetlist2t = [];
      let lastbetlist2l = [];
      let lastbetlist3t = [];
      let lastbetlist3tod = [];
      let lastbetlist3l = [];
      let lastbetlist3f = [];

      // เลข 3 ตัว
      if (action.payload.length == 3) {
        const newnumberstr = `${action.payload}`;
        let newstate = {
          number1: newnumberstr.substring(0, 1),
          number2: newnumberstr.substring(1, 2),
        };
        let newaction = {
          payload: newnumberstr.substring(2, 3),
        };
        // typerun = 31 คือ 3ตัวบน
        // typerun = 32 คือ 3ตัวโต๊ด
        // typerun = 33 คือ 3ตัวหน้า
        // typerun = 34 คือ 3ตัวล่าง

        const numbertop31 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 31
        );
        if (numbertop31) {
          // เช็คต่ออีกว่าเป็นแบบยกเลิก หรือเพิ่มใหม่
          const checknewnumber = betlist3t.find(
            (x) => x.number == newnumberstr
          );
          if (checknewnumber) {
            newbetlist3t = betlist3t.filter((x) => x.number !== newnumberstr);
          } else {
            newbetlist3t = getnumber(
              lottotypeitems,
              newstate,
              newaction,
              betlist3t,
              31,
              tod3
            );
            lastbetlist3t = getnumberlast(
              lottotypeitems,
              newstate,
              newaction,
              betlist3t,
              31,
              tod3
            );
            newlottonotify = true;
          }
        }

        const number3tod = lottotypeitems.find(
          (x) => x.active == true && x.tode == 1
        );
        if (number3tod) {
          const checknewnumber = betlist3tod.find(
            (x) => x.number == newnumberstr
          );
          if (checknewnumber) {
            newbetlist3tod = betlist3tod.filter(
              (x) => x.number !== newnumberstr
            );
          } else {
            newbetlist3tod = getnumbertod3(
              lottotypeitems,
              newstate,
              newaction,
              betlist3tod,
              1
            );
            lastbetlist3tod = [{ number: newnumberstr }];
            newlottonotify = true;
          }
        }

        const numbertop34 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 34
        );
        if (numbertop34) {
          const checknewnumber = betlist3l.find(
            (x) => x.number == newnumberstr
          );
          if (checknewnumber) {
            newbetlist3l = betlist3l.filter((x) => x.number !== newnumberstr);
          } else {
            newbetlist3l = getnumber(
              lottotypeitems,
              newstate,
              newaction,
              betlist3l,
              34,
              tod3
            );
            lastbetlist3l = getnumberlast(
              lottotypeitems,
              newstate,
              newaction,
              betlist3l,
              34,
              tod3
            );
            newlottonotify = true;
          }
        }

        const numbertop33 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 33
        );
        if (numbertop33) {
          const checknewnumber = betlist3f.find(
            (x) => x.number == newnumberstr
          );
          if (checknewnumber) {
            newbetlist3f = betlist3f.filter((x) => x.number !== newnumberstr);
          } else {
            newbetlist3f = getnumber(
              lottotypeitems,
              newstate,
              newaction,
              betlist3f,
              33,
              tod3
            );
            lastbetlist3f = getnumberlast(
              lottotypeitems,
              newstate,
              newaction,
              betlist3f,
              33,
              tod3
            );
            newlottonotify = true;
          }
        }
      }

      // เลข 2 ตัว
      if (action.payload.length == 2) {
        const newnumberstr = `${action.payload}`;

        let newstate = {
          number1: newnumberstr.substring(0, 1),
          number2: newnumberstr.substring(1, 2),
        };
        let newaction = {
          payload: newnumberstr.substring(1, 2),
        };

        const checknewbetlist2t = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 3
        );

        if (checknewbetlist2t) {
          const checknewnumber = betlist2t.find(
            (x) => x.number == newnumberstr
          );
          if (checknewnumber) {
            newbetlist2t = betlist2t.filter((x) => x.number !== newnumberstr);
          } else {
            newbetlist2t = getnumbet2t(
              lottotypeitems,
              betlist2t,
              newstate,
              newaction,
              3
            );
            lastbetlist2t = getnumbet2tlast(
              lottotypeitems,
              betlist2t,
              newstate,
              newaction,
              3
            );
            newlottonotify = true;
          }
        }

        const checknewbetlist2l = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 4
        );
        if (checknewbetlist2l) {
          const checknewnumber = betlist2l.find(
            (x) => x.number == newnumberstr
          );
          if (checknewnumber) {
            newbetlist2l = betlist2l.filter((x) => x.number !== newnumberstr);
          } else {
            newbetlist2l = getnumbet2t(
              lottotypeitems,
              betlist2l,
              newstate,
              newaction,
              4
            );
            lastbetlist2l = getnumbet2tlast(
              lottotypeitems,
              betlist2l,
              newstate,
              newaction,
              4
            );
            newlottonotify = true;
          }
        }
      }

      const newlottototal =
        parseInt(newbetlistrt.length) +
        parseInt(newbetlistrl.length) +
        parseInt(newbetlist2t.length) +
        parseInt(newbetlist3t.length) +
        parseInt(newbetlist3tod.length) +
        parseInt(newbetlist3l.length) +
        parseInt(newbetlist3f.length) +
        parseInt(newbetlist2l.length);

      const addlottototal =
        parseInt(newlottototal) - parseInt(state.lottototal);

      const betamount_newbetlistrt = newbetlistrt.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlistrl = newbetlistrl.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2t = newbetlist2t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2l = newbetlist2l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3t = newbetlist3t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3l = newbetlist3l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3tod = newbetlist3tod.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3f = newbetlist3f.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );

      const newbetamount =
        parseInt(betamount_newbetlistrt ? betamount_newbetlistrt : 0) +
        parseInt(betamount_newbetlistrl ? betamount_newbetlistrl : 0) +
        parseInt(betamount_newbetlist2t ? betamount_newbetlist2t : 0) +
        parseInt(betamount_newbetlist2l ? betamount_newbetlist2l : 0) +
        parseInt(betamount_newbetlist3t ? betamount_newbetlist3t : 0) +
        parseInt(betamount_newbetlist3l ? betamount_newbetlist3l : 0) +
        parseInt(betamount_newbetlist3tod ? betamount_newbetlist3tod : 0) +
        parseInt(betamount_newbetlist3f ? betamount_newbetlist3f : 0);
      return {
        ...state,
        betlistrt: newbetlistrt,
        betlistrl: newbetlistrl,
        betlist2t: newbetlist2t,
        betlist2l: newbetlist2l,
        betlist3t: newbetlist3t,
        betlist3l: newbetlist3l,
        betlist3tod: newbetlist3tod,
        betlist3f: newbetlist3f,
        lottototal: newlottototal,
        lottonotify: newlottonotify,
        last_betlistrt: newlast_betlistrt,
        addlottototal,
        betamount: newbetamount,
        last_betlist3t: lastbetlist3t,
        last_betlist3l: lastbetlist3l,
        last_betlist3f: lastbetlist3f,
        last_betlist3tod: lastbetlist3tod,
        last_betlist2t: lastbetlist2t,
        last_betlist2l: lastbetlist2l,
      };
    }

    // เคสกด วิ่งบน วิ่งล่าง
    case "handleBtnHeadRl3": {
      const {
        lottotypeitems,
        betlistrt,
        betlistrl,
        betlist2t,
        betlist2l,
        numbertype,
        betlist3t,
        betlist3tod,
        betlist3l,
        betlist3f,
      } = state;
      let newlottonotify = false;

      let newbetlistrt = betlistrt;
      let newbetlistrl = betlistrl;
      let newbetlist2t = betlist2t;
      let newbetlist2l = betlist2l;
      let newbetlist3t = betlist3t;
      let newbetlist3tod = betlist3tod;
      let newbetlist3l = betlist3l;
      let newbetlist3f = betlist3f;
      let newlast_betlistrt = [];
      const numbertext = action.payload;

      // เช้คก่อนว่าเลือกวิ่งบนหรือเปล่า
      const checknewbetlistrt = lottotypeitems.find(
        (x) => x.active == true && x.typerun == 1
      );
      if (checknewbetlistrt) {
        const checkdata = betlistrt.find((x) => x.number.includes(numbertext));
        if (checkdata) {
          newbetlistrt = betlistrt.filter(
            (x) => !x.number.includes(numbertext)
          );
        } else {
          newbetlistrt = getnumbetrt(
            lottotypeitems,
            betlistrt,
            state,
            action,
            1
          );
          newlottonotify = true;
        }
      }

      // เช้คก่อนว่าเลือกวิ่งล่างหรือเปล่า
      const checknewbetlistrl = lottotypeitems.find(
        (x) => x.active == true && x.typerun == 2
      );
      if (checknewbetlistrl) {
        const checkdata = betlistrl.find((x) => x.number.includes(numbertext));
        if (checkdata) {
          newbetlistrl = betlistrl.filter(
            (x) => !x.number.includes(numbertext)
          );
        } else {
          newbetlistrl = getnumbetrt(
            lottotypeitems,
            betlistrl,
            state,
            action,
            1
          );
          newlottonotify = true;
        }
      }

      const newlottototal =
        parseInt(newbetlistrt.length) +
        parseInt(newbetlistrl.length) +
        parseInt(newbetlist2t.length) +
        parseInt(newbetlist3t.length) +
        parseInt(newbetlist3tod.length) +
        parseInt(newbetlist3l.length) +
        parseInt(newbetlist3f.length) +
        parseInt(newbetlist2l.length);

      const addlottototal =
        parseInt(newlottototal) - parseInt(state.lottototal);

      const betamount_newbetlistrt = newbetlistrt.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlistrl = newbetlistrl.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2t = newbetlist2t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2l = newbetlist2l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3t = newbetlist3t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3l = newbetlist3l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3tod = newbetlist3tod.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3f = newbetlist3f.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );

      const newbetamount =
        parseInt(betamount_newbetlistrt ? betamount_newbetlistrt : 0) +
        parseInt(betamount_newbetlistrl ? betamount_newbetlistrl : 0) +
        parseInt(betamount_newbetlist2t ? betamount_newbetlist2t : 0) +
        parseInt(betamount_newbetlist2l ? betamount_newbetlist2l : 0) +
        parseInt(betamount_newbetlist3t ? betamount_newbetlist3t : 0) +
        parseInt(betamount_newbetlist3l ? betamount_newbetlist3l : 0) +
        parseInt(betamount_newbetlist3tod ? betamount_newbetlist3tod : 0) +
        parseInt(betamount_newbetlist3f ? betamount_newbetlist3f : 0);

      return {
        ...state,
        betlistrt: newbetlistrt,
        betlistrl: newbetlistrl,
        lottonotify: newlottonotify,
        addlottototal,
        lottototal: newlottototal,
        betamount: newbetamount,
      };
    }

    case "addcountter": {
      const {
        lottotypeitems,
        betlistrt,
        betlistrl,
        betlist2t,
        betlist2l,
        numbertype,
        betlist3t,
        betlist3tod,
        betlist3l,
        betlist3f,
      } = state;
      let newlottonotify = false;
      let number1 = "";
      let number2 = "|";
      let number3 = "_";
      let count = 0;
      let newbetlistrt = betlistrt;
      let newbetlistrl = betlistrl;
      let newbetlist2t = betlist2t;
      let newbetlist2l = betlist2l;
      let newbetlist3t = betlist3t;
      let newbetlist3tod = betlist3tod;
      let newbetlist3l = betlist3l;
      let newbetlist3f = betlist3f;
      let newlast_betlistrt = [];

      let lastbetlistrt = [];
      let lastbetlistrl = [];
      let lastbetlist2t = [];
      let lastbetlist2l = [];
      let lastbetlist3t = [];
      let lastbetlist3tod = [];
      let lastbetlist3l = [];
      let lastbetlist3f = [];

      if (state.count == 0) {
        number1 = action.payload;
        number2 = "|";
        number3 = "_";
        count = state.count + 1;

        if (numbertype == 0) {
          number1 = "|";
          number2 = "_";
          number3 = "_";
          count = 0;
          // เข้าเงื่อนไนสุดท้ายเพิ่ม รายการลง lottobetlist
          // หาวิ่งบน ว่ามีมั้ย
          const checknewbetlistrt = lottotypeitems.find(
            (x) => x.active == true && x.typerun == 1
          );
          if (checknewbetlistrt) {
            newbetlistrt = getnumbetrt(
              lottotypeitems,
              betlistrt,
              state,
              action,
              1
            );
            if (newbetlistrt.length > betlistrt.length) {
              lastbetlistrt = [{ number: action.payload }];
              newlottonotify = true;
            }
          }

          // หาวิ่งล่าง
          const checknewbetlistrl = lottotypeitems.find(
            (x) => x.active == true && x.typerun == 2
          );
          if (checknewbetlistrl) {
            newbetlistrl = getnumbetrt(
              lottotypeitems,
              betlistrl,
              state,
              action,
              2
            );
            if (newbetlistrl.length > betlistrl.length) {
              lastbetlistrl = [{ number: action.payload }];
              newlottonotify = true;
            }
          }

          //lottototal
        }
      }
      if (state.count == 1) {
        number1 = state.number1;
        number2 = action.payload;
        number3 = "|";
        count = state.count + 1;
        if (numbertype == 2) {
          number1 = "|";
          number2 = "_";
          number3 = "_";
          count = 0;

          const checknewbetlist2t = lottotypeitems.find(
            (x) => x.active == true && x.typerun == 3
          );

          if (checknewbetlist2t) {
            newbetlist2t = getnumbet2t(
              lottotypeitems,
              betlist2t,
              state,
              action,
              3
            );
            lastbetlist2t = getnumbet2tlast(
              lottotypeitems,
              betlist2t,
              state,
              action,
              3
            );
          }

          const checknewbetlist2l = lottotypeitems.find(
            (x) => x.active == true && x.typerun == 4
          );
          if (checknewbetlist2l) {
            newbetlist2l = getnumbet2t(
              lottotypeitems,
              betlist2l,
              state,
              action,
              4
            );
            lastbetlist2l = getnumbet2tlast(
              lottotypeitems,
              betlist2l,
              state,
              action,
              4
            );
          }

          newlottonotify = true;
        }
      }
      if (state.count == 2) {
        number1 = "|";
        number2 = "_";
        number3 = "_";
        count = 0;

        // typerun = 31 คือ 3ตัวบน
        // typerun = 32 คือ 3ตัวโต๊ด
        // typerun = 33 คือ 3ตัวหน้า
        // typerun = 34 คือ 3ตัวล่าง

        const numbertop31 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 31
        );
        if (numbertop31) {
          newbetlist3t = getnumber(
            lottotypeitems,
            state,
            action,
            betlist3t,
            31,
            tod3
          );
          lastbetlist3t = getnumberlast(
            lottotypeitems,
            state,
            action,
            betlist3t,
            31,
            tod3
          );
        }

        const number3tod = lottotypeitems.find(
          (x) => x.active == true && x.tode == 1
        );
        if (number3tod) {
          newbetlist3tod = getnumbertod3(
            lottotypeitems,
            state,
            action,
            betlist3tod,
            1
          );
        }

        const numbertop34 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 34
        );
        if (numbertop34) {
          newbetlist3l = getnumber(
            lottotypeitems,
            state,
            action,
            betlist3l,
            34,
            tod3
          );
          lastbetlist3l = getnumberlast(
            lottotypeitems,
            state,
            action,
            betlist3l,
            34,
            tod3
          );
        }

        const numbertop33 = lottotypeitems.find(
          (x) => x.active == true && x.typerun == 33
        );
        if (numbertop33) {
          newbetlist3f = getnumber(
            lottotypeitems,
            state,
            action,
            betlist3f,
            33,
            tod3
          );
          lastbetlist3f = getnumberlast(
            lottotypeitems,
            state,
            action,
            betlist3f,
            33,
            tod3
          );
        }
        newlottonotify = true;
      }

      const newlottototal =
        parseInt(newbetlistrt.length) +
        parseInt(newbetlistrl.length) +
        parseInt(newbetlist2t.length) +
        parseInt(newbetlist3t.length) +
        parseInt(newbetlist3tod.length) +
        parseInt(newbetlist3l.length) +
        parseInt(newbetlist3f.length) +
        parseInt(newbetlist2l.length);

      const addlottototal =
        parseInt(newlottototal) - parseInt(state.lottototal);

      const betamount_newbetlistrt = newbetlistrt.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlistrl = newbetlistrl.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2t = newbetlist2t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist2l = newbetlist2l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3t = newbetlist3t.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3l = newbetlist3l.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3tod = newbetlist3tod.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );
      const betamount_newbetlist3f = newbetlist3f.reduce(
        (total, list) => parseInt(total) + parseInt(list.amount),
        0
      );

      const newbetamount =
        parseInt(betamount_newbetlistrt ? betamount_newbetlistrt : 0) +
        parseInt(betamount_newbetlistrl ? betamount_newbetlistrl : 0) +
        parseInt(betamount_newbetlist2t ? betamount_newbetlist2t : 0) +
        parseInt(betamount_newbetlist2l ? betamount_newbetlist2l : 0) +
        parseInt(betamount_newbetlist3t ? betamount_newbetlist3t : 0) +
        parseInt(betamount_newbetlist3l ? betamount_newbetlist3l : 0) +
        parseInt(betamount_newbetlist3tod ? betamount_newbetlist3tod : 0) +
        parseInt(betamount_newbetlist3f ? betamount_newbetlist3f : 0);
      return {
        ...state,
        count,
        number1,
        number2,
        number3,
        betlistrt: newbetlistrt,
        betlistrl: newbetlistrl,
        betlist2t: newbetlist2t,
        betlist2l: newbetlist2l,
        betlist3t: newbetlist3t,
        betlist3l: newbetlist3l,
        betlist3tod: newbetlist3tod,
        betlist3f: newbetlist3f,
        lottototal: newlottototal,
        lottonotify: newlottonotify,
        addlottototal,
        betamount: newbetamount,
        last_betlistrl: lastbetlistrl,
        last_betlistrt: lastbetlistrt,
        last_betlist3t: lastbetlist3t,
        last_betlist3l: lastbetlist3l,
        last_betlist3f: lastbetlist3f,
        last_betlist3tod: lastbetlist3tod,
        last_betlist2t: lastbetlist2t,
        last_betlist2l: lastbetlist2l,
      };
    }
    case "delhandlelottoRate": {
      const { item } = action.payload;
      const { lottotypeitems } = state;
      const index = lottotypeitems.findIndex((x) => x.id == item.id);
      if (index != -1) {
        const data = lottotypeitems[index];
        // แสดงว่ามีค่า
        const NewItem = [
          ...lottotypeitems.slice(0, index),
          {
            ...data,
            active: !item.active,
          },
          ...lottotypeitems.slice(index + 1),
        ];
        return {
          ...state,
          lottotypeitems: NewItem,
          count: 0,
          number1: "|",
          number2: "_",
          number3: "_",
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case "handlelottoRate":
      const { item } = action.payload;
      const { lottotypeitems } = state;

      const index = lottotypeitems.findIndex((x) => x.id == item.id);
      if (index != -1) {
        const data = lottotypeitems[index];
        // แสดงว่ามีค่า
        const NewItem = [
          ...lottotypeitems.slice(0, index),
          {
            ...data,
            active: !item.active,
          },
          ...lottotypeitems.slice(index + 1),
        ];
        if (item.numbertype == 2 && item.active == false) {
          const dataitems = NewItem.map((x) => {
            const active =
              x.numbertype != 2 ? false : x.id == item.id ? true : x.active;
            return {
              ...x,
              active,
            };
          });
          return {
            ...state,
            lottotypeitems: dataitems,
            numbertype: item.numbertype,
            count: 0,
            number1: "|",
            number2: "_",
            number3: "_",
          };
        } else if (item.numbertype == 3 && item.active == false) {
          const dataitems = NewItem.map((x) => {
            const active =
              x.numbertype != 3 ? false : x.id == item.id ? true : x.active;
            return {
              ...x,
              active,
            };
          });

          // ถ้าเป็น tod ให้ ยกเลิก 3 ตัวกลับ
          if (item.tode == 1) {
            const itemtod = dataitems.map((x) => {
              const active =
                x.numbertype == 3 && x.backnumber == 1 ? false : x.active;
              return {
                ...x,
                active,
              };
            });

            return {
              ...state,
              lottotypeitems: itemtod,
              numbertype: item.numbertype,
              count: 0,
              number1: "|",
              number2: "_",
              number3: "_",
            };
          } else if (item.backnumber == 1) {
            // ถ้าเป็น 3กลับ ให้ ยกเลิก 3 ตัวโต๊ด
            const itemtod = dataitems.map((x) => {
              const active =
                x.numbertype == 3 && x.tode == 1 ? false : x.active;
              return {
                ...x,
                active,
              };
            });

            return {
              ...state,
              lottotypeitems: itemtod,
              numbertype: item.numbertype,
              count: 0,
              number1: "|",
              number2: "_",
              number3: "_",
            };
          } else {
            return {
              ...state,
              lottotypeitems: dataitems,
              numbertype: item.numbertype,
              count: 0,
              number1: "|",
              number2: "_",
              number3: "_",
            };
          }
        } else if (item.numbertype == 0 && item.active == false) {
          const dataitems = NewItem.map((x) => {
            const active =
              x.numbertype != 0 ? false : x.id == item.id ? true : x.active;
            return {
              ...x,
              active,
            };
          });
          return {
            ...state,
            lottotypeitems: dataitems,
            numbertype: item.numbertype,
            count: 0,
            number1: "|",
            number2: "_",
            number3: "_",
          };
        } else {
          return {
            ...state,
            lottotypeitems: NewItem,
            numbertype: item.numbertype,
            count: 0,
            number1: "|",
            number2: "_",
            number3: "_",
          };
        }

        // ยกเลิกประเภทปุ่มอื่นๆ
      } else {
        return {
          ...state,
        };
      }

    case "MEMBER_SAVE_FAILED_ACC":
      return {
        ...state,
        isLoading: false,
        notifyacc: true,
      };
    case MEMBER_GAMEALL_REQUEST:
      return {
        ...state,
        isLoadinggame: true,
        itemsgame: [],
      };
    case MEMBER_GAMEALL_SUCCESS:
      return {
        ...state,
        isLoadinggame: false,
        itemsgame: action.payload.item,
      };
    case MEMBER_GAMEALL_FAILED:
      return {
        ...state,
        isLoadinggame: true,
        itemsgame: [],
      };
    case MEMBER_CHANGEPASS_SUCCESS:
      return {
        ...state,
        changepass: true,
        isLoading: false,
      };
    case MEMBER_CHANGEPASS_FAILED:
      return {
        ...state,
        errorchangepass: true,
      };
    case MEMBER_WITHDRAWMONEY_REQUEST:
      return {
        ...state,
        isLoadingHis: true,
      };
    case MEMBER_WITHDRAWMONEY_FAILED:
      return {
        ...state,
        isLoadingHis: false,
      };
    case MEMBER_WITHDRAWMONEY_SUCCESS:
      return {
        ...state,
        isLoadingHis: false,
        winlossItems: action.payload.items,
        //winlossmeta: action.payload.meta,
        winloss: action.payload.item.winloss,
        memberwinloss: action.payload.item.memberwinloss,
        total: action.payload.item.total,
      };
    case MEMBER_SAVEACC_REQUEST:
      return {
        ...state,
        statusacc: {},
        //isLoading: true,
      };

    case MEMBER_OTPACC_REQUEST:
      return {
        ...state,
        statusacc: {},
        //isLoading: true,
      };
    case MEMBER_OTPACC_FAILED:
      return {
        ...state,
        isLoading: false,
        statusacc: action.payload,
      };
    case MEMBER_OTPACC_SUCCESS:
      const { accountlist } = state;

      const newitems =
        action.payload &&
        action.payload.reduce(
          (result, list) => [...result, list],
          [...accountlist]
        );
      return {
        ...state,
        statusacc: {},
        isLoading: false,
        accountlist: newitems,
        accountlistotp: [],
        accountlistotpconfirm: {},
      };
    case MEMBER_SAVEACC_SUCCESS:
      return {
        ...state,
        statusacc: {},
        isLoading: false,
        accountlistotp: [{ ...action.payload }],
      };
    case MEMBER_GETACC_REQUEST:
      return {
        ...state,
        statusacc: {},
        accountload: true,
        accountlist: [],
      };
    case MEMBER_GAMELIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        gamelist: [],
      };
    case MEMBER_GAMELIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gamelist: action.payload.item,
      };
    case MEMBER_GAMELOGIN_REQUEST:
      return {
        ...state,
        loadgame: true,
        link: null,
      };
    case MEMBER_GAMELOGIN_SUCCESS:
      return {
        ...state,
        loadgame: false,
        link: action.payload.link,
      };
    case MEMBER_GETACC_SUCCESS:
      let newlistotp = {};
      if (action.payload.itemotp.length > 0) {
        newlistotp = { ...action.payload.itemotp[0] };
      }
      return {
        ...state,
        accountload: false,
        accountlist: action.payload.item,
        accountlistotp: action.payload.itemotp,
        accountlistotpconfirm: newlistotp,
      };
    case MEMBER_GETACC_FAILED:
      return {
        ...state,
        accountload: false,
        accountlist: [],
      };
    case MEMBER_GETHISTORY_REQUEST:
      return {
        ...state,
        isLoadingHis: true,
        //historyItems: [],
      };
    case MEMBER_GETHISTORY_SUCCESS:
      return {
        ...state,
        isLoadingHis: false,
        historyItems: action.payload.items,
        meta: action.payload.meta,
      };
    case MEMBER_GETHISTORY_FAILED:
      return {
        ...state,
        isLoadingHis: false,
        historyItems: [],
      };
    case MEMBER_WINLOSSAFF_REQUEST:
      return {
        ...state,
        isLoadingHis: true,
        //historyItems: [],
      };
    case MEMBER_WINLOSSAFF_SUCCESS:
      return {
        ...state,
        isLoadingHis: false,
        winlossItems: action.payload.items,
        winlossmeta: action.payload.meta,
        winloss: action.payload.winloss,
        memberwinloss: action.payload.memberwinloss,
        total: action.payload.total,
      };
    case MEMBER_WINLOSSAFF_FAILED:
      return {
        ...state,
        isLoadingHis: false,
        winlossItems: [],
      };
    case MEMBER_GETBETHISTORY_REQUEST:
      return {
        ...state,
        isLoadingHis: true,
        //historyItems: [],
      };
    case MEMBER_GETBETHISTORY_SUCCESS:
      return {
        ...state,
        isLoadingHis: false,
        historyItems: action.payload.items,
        meta: action.payload.meta,
      };
    case MEMBER_GETBETHISTORY_FAILED:
      return {
        ...state,
        isLoadingHis: false,
        historyItems: [],
      };
    case MEMBER_GETMEMBERAFF_REQUEST:
      return {
        ...state,
        isLoadingHis: true,
        //historyItems: [],
      };
    case MEMBER_GETMEMBERAFF_SUCCESS:
      return {
        ...state,
        isLoadingHis: false,
        historyItems: action.payload.items,
        meta: action.payload.meta,
      };
    case MEMBER_GETMEMBERAFF_FAILED:
      return {
        ...state,
        isLoadingHis: false,
        historyItems: [],
      };
    case MEMBER_CINFIRM_REQUEST:
      return {
        ...state,
        isLoading: true,
        //depositcomplete: false,
        //item: {},
      };
    case MEMBER_DEPOSIT_REQUEST:
      return {
        ...state,
        isLoading: true,

        //depositcomplete: false,
        item: {},
      };
    case MEMBER_CHECKBOOKING_SUCCESS:
      return {
        ...state,
        //depositcomplete: false,
        isLoading: false,
        item: action.payload,
        showbox: true,
      };
    case MEMBER_DELBOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showbox: false,
        item: action.payload,
      };
    case MEMBER_CHECKBOOKING_SUCCESS_FAILED:
      return {
        ...state,
        //depositcomplete: false,
        isLoading: false,
        item: { ...action.payload },
        showbox: true,
        showrun: true,
      };

    case MEMBER_CINFIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showbox: true,
      };

    case MEMBER_DEPOSIT_SUCCESS:
      return {
        ...state,
        //depositcomplete: false,
        isLoading: false,
        item: action.payload,
        showbox: true,
      };
    case MEMBER_CANCELBOOKING_SUCCESS:
      return {
        ...state,
        //depositcomplete: false,
        isLoading: false,
        item: action.payload,
        showbox: false,
      };
    case MEMBER_DEPOSIT_SUCCESS_OK:
      return {
        ...state,
        isLoading: false,
        depositcomplete: true,
        item: { ...state.item, show: false },
      };
    case MEMBER_DEPOSIT_FAILED:
      return {
        ...state,
        //depositcomplete: false,
        notify: false,
        isLoading: false,
        showbox: false,
        showrun: false,
        item: {},
        wait: action.payload,
        bigpay: false,
      };
    case "MEMBER_DEPOSIT_FAILED_BIGPAY":
      return {
        ...state,
        //depositcomplete: false,
        notify: false,
        isLoading: false,
        showbox: false,
        showrun: false,
        item: {},
        bigpay: true,
      };
    case MEMBER_DEPOSIT_FAILED_ALL:
      return {
        ...state,
        depositcomplete: false,
        notify: false,
        notifyacc: false,
        isLoading: false,
        showbox: false,
        item: {},
        wait: action.payload,
        statusacc: {},
        changepass: false,
        errorchangepass: false,
        bigpay: false,
      };
    case MEMBER_REQUEST:
      return {
        ...state,
        isLoading: true,
        //depositcomplete: false,
        items: [],
      };
    case MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notify: false,
        notifysuccess: false,
        //depositcomplete: false,
        datanotify: {},
        items: { ...action.payload.item, ...action.payload.profile },
      };
    case "MEMBER_AFF_SUCCESS":
      return {
        ...state,
        items: { ...state.items, ...action.payload.item },
      };
    case MEMBER_GETCREDIT_REQUEST:
      return {
        ...state,
        isLoading: true,
        //depositcomplete: false,
      };
    case MEMBER_GETCREDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formx: {
          ...state.formx,
          Amount: action.payload,
        },
      };
    case MEMBER_GETDATA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MEMBER_GETDATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case MEMBER_WITHDRAW_REQUEST:
      return {
        ...state,
        isLoading: true,
        withdrawItems: [],
      };
    case MEMBER_WITHDRAW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: { ...state.items, ...action.payload },
        withdrawItems: [
          {
            amount: action.payload.Amount,
            dtnow: moment(Date.now()).format("DD/MM/YYYY HH:mm"),
          },
        ],
        notify: true,
      };
    case MEMBER_WITHDRAW_FAILED:
      return {
        ...state,
        isLoading: false,
        notify: true,
        withdrawItems: [],
      };
    case MEMBER_GETWITHDRAW_REQUEST:
      return {
        ...state,
        isLoading: true,
        withdrawItems: [],
      };
    case MEMBER_GETWITHDRAW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notify: false,
        withdrawItems: action.payload,
      };
    case MEMBER_SAVE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case MEMBER_SAVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload },
        notifysuccess: true,
      };
    case MEMBER_SAVE_FAILED:
      return {
        ...state,
        isLoading: false,

        datanotify: { ...action.payload },
      };
    case MEMBER_CHECKWITHDRAW_REQUEST:
      return {
        ...state,
        isLoading: true,
        withdrawItems: [],
      };
    case MEMBER_CHECKWITHDRAW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        withdrawItems: action.payload,
      };
    case MEMBER_CHECKWITHDRAW_FAILED:
      return {
        ...state,
        isLoading: false,
        withdrawItems: [],
      };
    default:
      return state;
  }
}
