import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
//import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Snackbar from "@material-ui/core/Snackbar";
import CardContent from "@material-ui/core/CardContent";

import { red } from "@material-ui/core/colors";

import { withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { Alert } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as memberActions from "redux/member/action";
import * as uiActions from "redux/ui/action";

import * as yup from "yup";
import TableForm from "./Table";
import useFetch from "lib/useFetch";
import { API_URL, addCommas } from "modules/configApp";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  rootbg: {
    backgroundColor: "#fff",
  },
  img: {
    flexGrow: 1,
  },
  rootLoading: {
    zIndex: 10,
    top: 150,
    /*position: "absolute",*/
    textAlign: "center",
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  headpaper: {
    backgroundColor: "#999695",
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitleC = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      Amount: yup.string().required(),
    }),
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const [depositerr, setdepositerr] = React.useState(false);

  const handleClosedepositerr = () => {
    setdepositerr(false);
  };

  const submit = ({ Amount }) => {
    if (parseFloat(items.credit) >= parseFloat(Amount)) {
      if (parseFloat(Amount) < parseFloat(items.agent.minwithdraw)) {
        setdepositerr(true);
      } else {
        const action = memberActions.WithdrawAgent({ Amount, ...props });
        dispatch(action);
      }
    } else {
      setOpen(true);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const action = memberActions.GetCredit({ ...props });
    dispatch(action);

    const uiaction = uiActions.chatShow(false);
    dispatch(uiaction);

    const actionwithdraw = memberActions.CheckWithDraw();
    dispatch(actionwithdraw);
  }, []);

  const { notify, withdrawItems, isLoading, items, item } = useSelector(
    (store) => store.member
  );

  const { notify: notifyui } = useSelector((store) => store.ui);

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const action = memberActions.clearNotify();
    dispatch(action);
  };

  const handleCloseState = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const goBack = (path) => {
    props.history.push(path);
  };

  const handleClosesuccess = () => {
    //setshownot(false);
    const action = uiActions.clearNotify();
    dispatch(action);
    const actionmember = memberActions.clearNotify();
    dispatch(actionmember);
    props.history.push("/dashboard");
  };

  const checkpromotion = useFetch(
    API_URL + "/booking/1/checkpromotion",
    {},
    "get"
  );

  const submit2 = () => {
    btnSubmit.current.click();
  };
  const btnSubmit = React.useRef(null);
  return (
    <>
      <div className="headpaddingtop"></div>
      <Container
        maxWidth="md"
        style={{
          paddingTop: 20,
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
        }}
      >
        <Button
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          variant="outlined"
          onClick={() => goBack("/")}
          className="btnback"
        >
          ย้อนกลับ
        </Button>
      </Container>
      <Container maxWidth="md">
        <div style={{ height: 10 }}></div>
        {isLoading ? (
          <div className={classes.rootLoading}>
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : withdrawItems.length > 0 ? (
          <TableForm />
        ) : (
          <Card className={classes.root}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h5"
                className="headtitle"
              >
                {t("withdraw.label")}
              </Typography>
              <div style={{ height: 20 }}></div>
              {checkpromotion?.turnoverstatus == 1 && (
                <>
                  <Alert
                    severity="warning"
                    // className={classes.formalert}
                    //icon={<CardGiftcardIcon />}
                  >
                    <font className="divbink">
                      ไม่สามารถถอนเงินได้ คุณมีโปรโมชั่น กำลังใช้งานอยู่
                    </font>
                  </Alert>
                  <div style={{ height: 2 }}></div>
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>วันที่เริ่มโปร</StyledTableCell>
                          <StyledTableCell align="left">
                            โปรที่เลือก
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            เงื่อนไขทำเทิร์น
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>
                            {checkpromotion.dtnow}
                          </StyledTableCell>

                          <StyledTableCell style={{ color: "green" }}>
                            โบนัส {checkpromotion.pbonus} %
                          </StyledTableCell>
                          <StyledTableCell style={{ color: "red" }}>
                            {addCommas(
                              parseFloat(checkpromotion.turnover).toFixed(2)
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>ยอดฝาก</StyledTableCell>
                          <StyledTableCell align="left">
                            รับโบนัส
                          </StyledTableCell>

                          <StyledTableCell>เทิร์นที่ทำได้</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell style={{ color: "green" }}>
                            {addCommas(
                              parseFloat(checkpromotion.amount).toFixed(2)
                            )}
                          </StyledTableCell>

                          <StyledTableCell style={{ color: "red" }}>
                            {addCommas(
                              parseFloat(checkpromotion.bonus).toFixed(2)
                            )}
                          </StyledTableCell>

                          <StyledTableCell style={{ color: "red" }}>
                            {addCommas(
                              parseFloat(checkpromotion.memberturnover).toFixed(
                                2
                              )
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {checkpromotion?.turnoverstatus == 2 && (
                <div className="boxacc">
                  <div className="boxaccleft"></div>
                  <div className="boxaccright">
                    <Avatar>
                      <img
                        src={`/images/bank/${items?.accbank}.png`}
                        height="50"
                      ></img>
                    </Avatar>
                  </div>
                  <div className="boxaccleft">ธนาคาร</div>
                  <div className="boxaccright">{items?.accbank}</div>
                  <div className="boxaccleft">ชื่อบัญชี</div>
                  <div className="boxaccright">{items?.accname}</div>
                  <div className="boxaccleft">เลขบัญชี</div>
                  <div className="boxaccright">{items?.accno}</div>
                </div>
              )}
              <div style={{ height: 10 }}></div>
              {checkpromotion?.turnoverstatus == 2 && (
                <>
                  <div className="btnnew3">
                    จำนวนเงินที่ถอนได้ &nbsp;:&nbsp;
                    <span className="bnxm">
                      {addCommas(parseFloat(items.credit).toFixed(0))}
                    </span>
                  </div>
                  <div style={{ height: 10 }}></div>
                  <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit(submit)}
                  >
                    <input
                      ref={register}
                      type="text"
                      id="credit"
                      name="Amount"
                      class="form__input"
                      placeholder={`${t("depositmin.label")} ${
                        items?.agent?.minwithdraw
                      } ${t("bath.label")}`}
                      //value={credit ? credit : null}
                      //onChange={setCreditNew2}
                    />

                    <div style={{ height: 10 }}></div>
                    <div className="btnsubmit" onClick={submit2}>
                      {t("btnconfirm.label")}
                    </div>

                    <button
                      type="submit"
                      ref={btnSubmit}
                      className="btnnone"
                    ></button>
                  </form>
                  <div style={{ height: 10 }}></div>
                </>
              )}
            </CardContent>
          </Card>
        )}

        <div style={{ height: 90 }}></div>
        <Snackbar
          open={notify}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            แจ้งถอนเรียบร้อย
          </Alert>
        </Snackbar>

        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleCloseState}
        >
          <Alert onClose={handleCloseState} severity="error">
            คุณป้อนยอดเงินเกิน กรุณาป้อนใหม่
          </Alert>
        </Snackbar>
      </Container>

      <Dialog
        open={notifyui?.wsuccess}
        onClose={handleClosesuccess}
        //aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleC id="alert-dialog-title" onClose={handleClosesuccess}>
          {"ยินดีด้วยค่ะ"}
        </DialogTitleC>
        <DialogContent style={{ textAlign: "center" }}>
          <div>
            <img src="/images/compelet.png" height="50"></img>
          </div>
          <div>ยอดเงินของคุณได้รับการยืนยันแล้วค่ะ</div>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}></DialogActions>
      </Dialog>
      <Dialog
        open={depositerr}
        onClose={handleClosedepositerr}
        //aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitleC id="alert-dialog-title" onClose={handleClosedepositerr}>
          {"ขออภัยค่ะ"}
        </DialogTitleC>
        <DialogContent style={{ textAlign: "center" }}>
          <div>
            {/*<img src="/images/compelet.png" height="50"></img>*/}
            <img src="/images/error.png" height="50"></img>
          </div>
          {/*<div>ยอดเงินของคุณได้รับการยืนยันแล้วค่ะ</div>*/}
          <div>ถอนขั้นต่ำต้อง {items?.agent?.minwithdraw} ขึ้นไป</div>
        </DialogContent>
        <DialogActions style={{ textAlign: "center" }}></DialogActions>
      </Dialog>
    </>
  );
}
