import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import useFetch from "lib/useFetch";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import { API_URL, addCommas } from "modules/configApp";
import * as memberActions from "redux/member/action";

import * as yup from "yup";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#eaf4fc",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    flexGrow: 1,
  },
  img: {
    flexGrow: 1,
  },
  top10: {
    paddingTop: 100,
  },
  rootCard: {
    maxWidth: 345,
  },
  rootbg: {
    backgroundColor: "#171414",
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    winloss,
    winlossItems: historyItems,
    winlossmeta: meta,
    isLoadingHis: isLoading,
  } = useSelector((store) => store.member);

  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    validationSchema: yup.object().shape({
      Amount: yup.string().required(),
    }),
  });

  /*
  const { register, handleSubmit , errors} = useForm()
*/
  const submit = ({ Amount }) => {
    if (parseFloat(winloss) >= parseFloat(Amount)) {
      const action = memberActions.WithdrawMoney({ Amount, ...props });
      dispatch(action);
    } else {
      setOpen(true);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    const action = memberActions.clearNotify();
    dispatch(action);
  };

  const handleCloseState = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  /*
  const handleClosesuccess = () => {
    //setshownot(false);
    const action = uiActions.clearNotify();
    dispatch(action);
    const actionmember = memberActions.clearNotify();
    dispatch(actionmember);
    props.history.push("/dashboard");
  };
*/

  const onChange = (e, page) => {
    setPage(page);
  };

  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  useEffect(() => {
    const actionwithdraw = memberActions.GetMemberWinlossAff(page);
    dispatch(actionwithdraw);
  }, [page]);

  const btnSubmit = React.useRef(null);
  const submit2 = () => {
    btnSubmit.current.click();
  };

  return (
    <div className="bgconx">
      <div className="btnnew3">
        จำนวนเงินที่ถอนได้ &nbsp;:&nbsp;
        <span className="bnxm">
          {addCommas(parseFloat(winloss).toFixed(0))}
        </span>
      </div>
      <div style={{ height: 10 }}></div>
      <form className={classes.form} noValidate onSubmit={handleSubmit(submit)}>
        <input
          ref={register}
          type="text"
          id="credit"
          name="Amount"
          class="form__input"
          placeholder={`${t("depositmin.label")} 100 บาท`}
          //value={credit ? credit : null}
          //onChange={setCreditNew2}
        />
        <div style={{ height: 10 }}></div>
        <div className="btnsubmit" onClick={submit2}>
          แจ้งถอนรายได้
        </div>

        <button type="submit" ref={btnSubmit} className="btnnone"></button>
      </form>
      <div style={{ height: 20 }}></div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            {props.type == 2 && (
              <TableRow>
                <StyledTableCell align="left">วันที่</StyledTableCell>
                <StyledTableCell align="left">ยอดเล่น</StyledTableCell>

                <StyledTableCell align="left">รายได้</StyledTableCell>
                <StyledTableCell align="left">ยอดถอน</StyledTableCell>
              </TableRow>
            )}
            {props.type == 1 && (
              <TableRow>
                <StyledTableCell align="left">วันที่</StyledTableCell>
                <StyledTableCell align="left">ยอดฝาก</StyledTableCell>
                <StyledTableCell align="left">ยอดฝากสะสม</StyledTableCell>
                <StyledTableCell align="left">รายได้</StyledTableCell>
                <StyledTableCell align="left">ยอดถอน</StyledTableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {isLoading && (
              <div className={classes.rootLoading}>
                <div class="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}

            {historyItems.map((row, index) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>
                  {row.startdate} ถึง {row.enddate}
                </StyledTableCell>

                <StyledTableCell style={{ color: "green" }}>
                  {addCommas(parseFloat(row.winloss).toFixed(0))}
                </StyledTableCell>

                <StyledTableCell style={{ color: "green" }}>
                  {addCommas(parseFloat(row.money_balance).toFixed(0))}
                </StyledTableCell>
                <StyledTableCell style={{ color: "red" }}>
                  {addCommas(parseFloat(row.withdraw).toFixed(0))}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {historyItems.length == 0 && (
          <div className="datano">ยังไม่มีข้อมูล</div>
        )}
        <div className={classes.rootpg}>
          {historyItems.length > 0 && (
            <Pagination
              count={meta?.totalPage}
              color="secondary"
              onChange={onChange}
              //siblingCount={0}
            />
          )}
        </div>
      </TableContainer>
      <div style={{ height: 150 }}></div>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleCloseState}
      >
        <Alert onClose={handleCloseState} severity="error">
          คุณป้อนยอดเงินเกินรายได้ กรุณาป้อนใหม่
        </Alert>
      </Snackbar>
    </div>
  );
}
